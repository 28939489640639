import _ from 'lodash';
import React, { Component } from 'react';
import { FilePdfOutlined , FileOutlined} from '@ant-design/icons';

import ClaimContext from './claim_context';

class FoodListItem extends Component {
  constructor(props) {
    super(props);
    this.renderUploadThumb = this.renderUploadThumb.bind(this);
  }

  renderUploadThumb() {
    if (_.includes(this.props.document.fileType, 'image')) {
      return (
        <div style={{ width: '150px' }}>
          <img
            src={this.props.document?.fileListItem?.thumbUrl ? this.props.document.fileListItem.thumbUrl : ' '}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      );
    }
    if (_.includes(this.props.document.fileType, 'pdf')) {
      return (
        <div style={{ width: '100px' }}>
          <FilePdfOutlined style={{ fontSize: '25px', color: '#BFBFBF' }} />
        </div>
      );
    }
    return (
      <div style={{ width: '100px' }}>
        <FileOutlined style={{ fontSize: '25px', color: '#BFBFBF' }} />
      </div>
    );
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <>
            <p>
              Item Lost:
              {this.props.document.itemLost}
            </p>
            <p>
              Quantity:
              {this.props.document.quantity}
            </p>
            <p>
              Unit Price:
              {this.props.document.unitPrice}
            </p>
          </>
        )}
      </ClaimContext.Consumer>
    );
  }
}

FoodListItem.contextType = ClaimContext;

export default FoodListItem;
