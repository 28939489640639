import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Divider } from 'antd';

import IncidentInfoForm from '../forms/form_incident_information';
import ClaimContext from '../claim_context';
import ClaimSummaryPanel from '../claim_summary_panel';

class IncidentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    // this.handleAccountValidation = this.handleAccountValidation.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => 'Are you sure you want to leave this page?  You will lose any unsaved data.';
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Grid>
            <Row>
              <Col xs>
                <h3 style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
                  Incident Information
                  {' '}
                </h3>
                <Divider />
              </Col>
            </Row>
            <div style={{ minHeight: '360px' }}>
              <Row>
                <Col xs>
                  <IncidentInfoForm context={context} />
                </Col>
                <Col xs>
                  <ClaimSummaryPanel />
                </Col>
              </Row>
              <Row>
                <Col xs />
              </Row>
            </div>
          </Grid>
        )}
      </ClaimContext.Consumer>
    );
  }
}

IncidentInfo.contextType = ClaimContext;

export default IncidentInfo;
