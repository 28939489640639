import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Layout from '../components/layout';
import ClaimContext from '../components/claim_context';

// Import the various claim steps
import AccountLookup from '../components/steps/account_lookup';
import CustomerInfo from '../components/steps/customer_info';
import IncidentInfo from '../components/steps/incident_info';
import Documentation from '../components/steps/documentation';
import ClaimSummary from '../components/steps/claim_summary';

import ClaimSteps from '../components/steps';

import '../components/antd_override.css';

// Create a Provider Component;
// A Top Level Provider Component for Context API to pass values down into children
class ClaimProvider extends Component {
  state = {
    step: 0,
    accountFound: false,
    validateBy: 'Account Number',
    guid: '',
    orderNumber: '',
    companyCode: '',
    customer: {},
    legal: {},
    incident: {},
    incidentDataModel: {},
    damageDescOptions: [],
    showCause: false,
    causesOptions: [],
    causeLabel: '',
    damagedItem: '',
    damagedDesc: '',
    documentList: [{
      index: 0,
      fileList: [],
    }],
    currentDocumentUpload: [],
    productUploadForms: [1],
    foodItemUploadForms: [1],
    foodItemList: [],
    foodUpoadForms: [1],
    disableClaimSubmission: true,
    disableFileSaveContinue: true,
    claimComments: '',
    foodItemDamageCode: '1012',
    showAddFilesForm: true,
    documentErrList: [],
    documentListIndex: 0,
    documentFieldConfig: [],
  };

  render() {
    return (
      <ClaimContext.Provider
        value={{
          state: this.state,
          setStep: (step) => this.setState({
            step,
          }),
          setAccountFound: (value) => this.setState({
            accountFound: value,
          }),
          setValidateBy: (value) => this.setState({
            validateBy: value,
          }),
          setCustomerInformation: (value) => this.setState({
            customer: value,
          }),
          setCustomerLegal: (value) => this.setState({
            legal: value,
          }),
          setIncidentInformation: (value) => this.setState({
            incident: value,
          }),
          setGuidId: (value) => this.setState({
            guid: value,
          }),
          setCompanyCode: (value) => this.setState({
            companyCode: value,
          }),
          setProductUploadForms: (value) => this.setState({
            productUploadForms: value,
          }),
          setIncidentDataModel: (value) => this.setState({
            incidentDataModel: value,
          }),

          setOrderNumber: (value) => this.setState({
            orderNumber: value,
          }),
          setDisableClaimSubmission: (value) => this.setState({
            disableClaimSubmission: value,
          }),
          setDisableFileSaveContinue: (value) => this.setState({
            disableFileSaveContinue: value,
          }),
          setDamageDescOptions: (value) => this.setState({
            damageDescOptions: value,
          }),
          setShowCause: (value) => this.setState({
            showCause: value,
          }),
          setCausesOptions: (value) => this.setState({
            causesOptions: value,
          }),
          setCauseLabel: (value) => this.setState({
            causeLabel: value,
          }),
          setDamagedItem: (value) => this.setState({
            damagedItem: value,
          }),
          setDamagedDesc: (value) => this.setState({
            damagedDesc: value,
          }),
          setFoodItemList: (value) => this.setState({
            foodItemList: value,
          }),
          setFoodItemErrList: (value) => this.setState({
            foodItemErrList: value,
          }),
          setDocumentErrList: (value) => this.setState({
            documentErrList: value,
          }),
          setFoodItemIndex: (value) => this.setState({
            foodItemIndex: value,
          }),
          setFoodItemTotal: (value) => this.setState({
            foodItemTotal: value,
          }),
          setDocumentList: (value) => this.setState({
            documentList: value,
          }),
          setDocumentListIndex: (value) => this.setState({
            documentListIndex: value,
          }),
          setDocumentFieldConfig: (value) => this.setState({
            documentFieldConfig: value,
          }),
          setCurrentDocumentUpload: (value) => this.setState({
            currentDocumentUpload: value,
          }),
          setClaimComments: (value) => this.setState({
            claimComments: value,
          }),
          setShowAddFilesForm: (value) => this.setState({
            showAddFilesForm: value,
          }),
        }}
      >
        {this.props.children}
      </ClaimContext.Provider>
    );
  }
}

// Main ClaimsApp entry point.  All steps are children of ClaimsApp
class ClaimsApp extends Component {
  constructor(props) {
    super(props);
    this.clickDetect = this.clickDetect.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.clickDetect);
  }

  clickDetect(e) {
    const dlObject = {};
    dlObject.currentStep = this.context.state.step;
    dlObject.element = e.target;
    dlObject.elementClasses = e.target.getAttribute('class');
    dlObject.elementId = e.target.getAttribute('id');
    dlObject.elementTarget = e.target;
    dlObject.elementUrl = e.target.getAttribute('href');
    if (e.target.tagName === 'A') {
      dlObject.event = 'gmt.linkClick';
    } else {
      dlObject.event = 'gmt.click';
    }
    // dataLayer.push(dlObject);
    // dataLayer.page = dlObject;
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Layout>
            {/* Display current step's content */}
            <Grid>
              <Row>
                <Col xs>
                  <ClaimSteps  />
                </Col>
              </Row>
            </Grid>
            {context.state.step === 0 && <AccountLookup />}
            {context.state.step === 1 && <CustomerInfo />}
            {context.state.step === 2 && <IncidentInfo />}
            {context.state.step === 3 && <Documentation />}
            {context.state.step === 4 && <ClaimSummary />}
          </Layout>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ClaimsApp.contextType = ClaimContext;

// Claims entry point with Provider Wrapped around App
class App extends Component {
  render() {
    return (
      <ClaimProvider>
        <ClaimsApp />
      </ClaimProvider>
    );
  }
}

export default App;
