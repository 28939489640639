import * as yup from 'yup';
import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Button, Input, Select } from 'antd';
import MaskedInput from 'react-text-mask';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import ClaimContext from '../claim_context';

const { Option } = Select;

// styled-component for the form (change color on validate)
const StyledInput = styled.div`
  margin: 0 2px 8px 0;
`;
const SectionTitle = styled.h5`
  margin: 10px 4px 4px 0;
  color: #424242;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const stepsMoveButtons = {
  float: 'right',
  marginTop: '25px',
};

class CustomerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleStepForward = this.handleStepForward.bind(this);
    this.handleStepBack = this.handleStepBack.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.submitted === true) {
      this.handleStepForward();
      return false;
    }
    return true;
  }

  handleStepForward() {
    this.context.setStep(2);
  }

  handleStepBack() {
    this.context.setStep(0);
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <Formik
            initialValues={{
              firstName: this.context.state.customer.firstName,
              lastName: this.context.state.customer.lastName,
              email: this.context.state.customer.email,
              emailVerify: this.context.state.customer.emailVerify,
              phoneNumber: this.context.state.customer.phoneNumber,
              phoneType: 'Mobile',
            }}
            validationSchema={yup.object().shape({
              firstName: yup.string().required('First Name is required.'),
              lastName: yup.string().required('Last Name is required.'),
              email: yup
                .string()
                .lowercase()
                .email()
                .required('Email is required.'),
              emailVerify: yup
                .string()
                .lowercase() // eslint-disable-next-line
                .test('match', 'Emails do not match.', function (emailVerify) {
                  return emailVerify === this.parent.email;
                }),
              phoneNumber: yup
                .string()
                .min(14)
                .required('Please enter a phone number.'),
              phoneType: yup.string().required('Phone Type is required.'),
            })}
            onSubmit={(values) => {
              // Set the state to reflect that results have been found
              this.context.setCustomerInformation(values);
              this.setState({ submitted: true });
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form id="form_customer_information">
                <Grid>
                  <Row>
                    <Col xs>
                      <Row>
                        <Col xs>
                          <SectionTitle>
                            What&apos;s Your Contact Information?
                          </SectionTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.firstName && errors.firstName && (
                            <FormIssue>{errors.firstName}</FormIssue>
                          )}
                          <Field
                            name="firstName"
                            id="firstName"
                          >
                            {({ field }) => (
                              <StyledInput>
                                <Input
                                  {...field}
                                  size="large"
                                  placeholder="First Name"
                                />
                              </StyledInput>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.lastName && errors.lastName && (
                            <FormIssue>{errors.lastName}</FormIssue>
                          )}
                          <Field
                            name="lastName"
                            id="lastName"
                          >
                            {({ field }) => (
                              <StyledInput>
                                <Input
                                  {...field}
                                  size="large"
                                  placeholder="Last Name"
                                />
                              </StyledInput>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.email && errors.email && (
                            <FormIssue id="errEmail">{errors.email}</FormIssue>
                          )}
                          <Field
                            name="email"
                            id="email"
                          >
                            {({ field }) => (
                              <StyledInput>
                                <Input
                                  {...field}
                                  size="large"
                                  placeholder="Email Address"
                                />
                              </StyledInput>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.emailVerify && errors.emailVerify && (
                            <FormIssue id="errEmailVerify">{errors.emailVerify}</FormIssue>
                          )}
                          <Field
                            name="emailVerify"
                            id="emailVerify"
                          >
                            {({ field }) => (
                              <StyledInput>
                                <Input
                                  {...field}
                                  size="large"
                                  placeholder="Verify Email Address"
                                />
                              </StyledInput>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <div style={{ margin: '0 auto' }}>
                            {touched.phoneType && errors.phoneType && (
                              <FormIssue>{errors.phoneType}</FormIssue>
                            )}
                            <Field
                              type="phoneType"
                              name="phoneType"
                            >
                              {() => (
                                <StyledInput>
                                  <Select
                                    id="phoneType"
                                    defaultValue="Mobile"
                                    style={{
                                      width: 100,
                                      float: 'left',
                                    }}
                                    onChange={(e) => setFieldValue('phoneType', e)}
                                    name="phoneType"
                                    placeholder="Phone Type"
                                    size="large"
                                  >
                                    <Option value="Mobile">Mobile</Option>
                                    <Option value="Home">Home</Option>
                                    <Option value="Work">Work</Option>
                                  </Select>
                                </StyledInput>
                              )}
                            </Field>

                            <Field
                              type="phoneNumber"
                              name="phoneNumber"
                            >
                              {({ field /* _form */ }) => (
                                <StyledInput>
                                  <MaskedInput
                                    {...field}
                                    id="phoneNumber"
                                    placeholder="Phone Number"
                                    className="custom_input"
                                    guide={false}
                                    style={{
                                      float: 'left',
                                      width: '55%',
                                      padding: '6px 11px',
                                      height: '40px',
                                      fontSize: '16px',
                                      borderRadius: '4px',
                                      border: '1px solid #d9d9d9',
                                      transition: 'all .3s',
                                    }}
                                    mask={[
                                      '(',
                                      /[1-9]/,
                                      /\d/,
                                      /\d/,
                                      ')',
                                      ' ',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      '-',
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                  />
                                </StyledInput>
                              )}
                            </Field>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.phoneNumber && errors.phoneNumber && (
                            <FormIssue id="errPhoneNumber">{errors.phoneNumber}</FormIssue>)}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {/* <Button type="primary" onClick={this.handleStepBack}>
                          <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: '4px', fontSize: '16px' }} /> Back
                        </Button> */}
                        </Col>
                        <Col xs>
                          <Button
                            type="primary"
                            style={stepsMoveButtons}
                            onClick={handleSubmit}
                          >
                            Save & Continue
                            {' '}
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              style={{ marginLeft: '4px', fontSize: '16px' }}
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </ClaimContext.Consumer>
    );
  }
}

CustomerInformation.contextType = ClaimContext;

export default CustomerInformation;
