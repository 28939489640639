import React, { Component } from 'react';
import { Button, Divider, Timeline } from 'antd';
import moment from 'moment';
import {
  FileProtectOutlined, UserOutlined, MailOutlined, PhoneOutlined,
  FileExclamationOutlined, QuestionCircleOutlined, UsergroupDeleteOutlined, CalendarOutlined, ClockCircleOutlined,
  EnvironmentOutlined, MessageOutlined
} from '@ant-design/icons';
import { Grid, Row, Col } from 'react-flexbox-grid';

import featureBg from '../images/feature_bg.png';
import ClaimContext from './claim_context';
import DocumentListSummaryItem from './documentation_upload_list_summary_item';
import FoodListSummaryItem from './documentation_upload_foodlist_summary_item';

class ClaimSummaryPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.renderDocumentSummary = this.renderDocumentSummary.bind(this);
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <div
            style={{
              marginLeft: '50px',
              backgroundImage: `url(${featureBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center bottom',
            }}
          >
            <Divider>
              <h5>Claim Summary</h5>
            </Divider>
            <Timeline>
              <Timeline.Item color="#4191F7" style={{ paddingBottom: '20px' }}>
                <Grid>
                  <Row>
                    <Col xs>
                      <span>
                        <h5>Customer Info</h5>
                      </span>
                    </Col>
                    <Col xs={2}>
                      <div style={{ float: 'right' }}>
                        {this.context.state.step === 4 ? (
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              this.context.setStep(1);
                            }}
                          >
                            Edit
                          </Button>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Timeline.Item>
              <Timeline.Item
                dot={<UserOutlined style={{ fontSize: '16px' }} />}
                color="blue"
                style={{ paddingTop: '2px', paddingBottom: '0px' }}
              >
                <p>
                  {this.context.state.customer.firstName}
                  {' '}
                  {this.context.state.customer.lastName}
                </p>
              </Timeline.Item>
              <Timeline.Item
                dot={<MailOutlined style={{ fontSize: '16px' }} />}
                color="blue"
                style={{ paddingBottom: '0px' }}
              >
                <p>{this.context.state.customer.email}</p>
              </Timeline.Item>
              <Timeline.Item
                dot={<PhoneOutlined style={{ fontSize: '16px' }} />}
                color="blue"
                style={{ paddingBottom: '20px' }}
              >
                <p>{this.context.state.customer.phoneNumber}</p>
              </Timeline.Item>

              <Timeline.Item color="#4191F7">
                <Grid>
                  <Row>
                    <Col xs>
                      <span>
                        <h5>Incident Info</h5>
                      </span>
                    </Col>
                    <Col xs={2}>
                      <div style={{ float: 'right' }}>
                        {this.context.state.incident.whatDamagedCode
                        && this.context.state.step === 4 ? (
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              this.context.setStep(2);
                            }}
                          >
                            Edit
                          </Button>
                          ) : null}
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Timeline.Item>
              <Timeline.Item
                dot={
                  <FileExclamationOutlined style={{ fontSize: '16px' }} />
                }
                color="blue"
                style={{ paddingBottom: '0px' }}
              >
                <p>{this.context.state.incident.damagedItemLabel}</p>
              </Timeline.Item>
              <Timeline.Item
                dot={
                  <QuestionCircleOutlined style={{ fontSize: '16px' }} />
                }
                color="blue"
                style={{ paddingBottom: '0px' }}
              >
                <p>{this.context.state.incident.damagedDescLabel}</p>
              </Timeline.Item>
              <Timeline.Item
                dot={
                  <UsergroupDeleteOutlined style={{ fontSize: '16px' }} />
                }
                color="blue"
                style={{ paddingBottom: '0px' }}
              >
                <p>{this.context.state.incident.causeLabel}</p>
                <p>{this.context.state.incident.causeCodeLabel}</p>
              </Timeline.Item>

              <Timeline.Item
                dot={<CalendarOutlined style={{ fontSize: '16px' }} />}
                color="blue"
                style={{ paddingBottom: '0px' }}
              >
                <p>
                  {this.context.state.incident.date ? moment(this.context.state.incident.date).format(
                    'dddd, MMMM Do YYYY',
                  ) : null}
                </p>
              </Timeline.Item>
              <Timeline.Item
                dot={
                  <ClockCircleOutlined style={{ fontSize: '16px' }} />
                }
                color="blue"
                style={{ paddingBottom: '15px' }}
              >
                <p>
                  {this.context.state.incident.date ? moment(this.context.state.incident.time).format('hh:mm a') : null}
                </p>
              </Timeline.Item>
              <Timeline.Item
                dot={<EnvironmentOutlined style={{ fontSize: '16px' }} />}
                color="blue"
              >
                <p>
                  {this.context.state.incident.line1 ? `${this.context.state.incident.line1}, ` : null}
                </p>
                <p>
                  {this.context.state.incident.line2 ? `${this.context.state.incident.line2}, ` : null}
                </p>
                <p>
                  {this.context.state.incident.line1 ? `${this.context.state.incident.city
                  }, ${
                    this.context.state.incident.stateCode
                  }  ${
                    this.context.state.incident.zipCode}` : null}
                </p>
              </Timeline.Item>
              <Timeline.Item
                dot={<MessageOutlined style={{ fontSize: '16px' }} />}
                color="blue"
              >
                <p>{this.context.state.incident.remarks}</p>
              </Timeline.Item>
              <Timeline.Item color="#4191F7">
                <Grid>
                  <Row>
                    <Col xs>
                      <span>
                        <h5>Documentation</h5>
                      </span>
                    </Col>
                    <Col xs={2}>
                      <div style={{ float: 'right' }}>
                        {this.context.state.documentList.length > 0
                        && this.context.state.step === 4 ? (
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              this.context.setStep(3);
                            }}
                          >
                            Edit
                          </Button>
                          ) : null}
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </Timeline.Item>
              {this.context.state.documentList.map((doc) => (
                  <Timeline.Item
                      key={doc.id}
                      dot={<FileProtectOutlined style={{ fontSize: '16px' }} />}
                      color="blue"
                  >
                    <DocumentListSummaryItem key={doc.id} document={doc} />
                  </Timeline.Item>

              ))}
              {this.context.state.foodItemList.map((doc) => (
              <Timeline.Item
                  key={doc.id}
                  dot={<FileProtectOutlined style={{ fontSize: '16px' }} />}
                  color="blue"
              >
                <FoodListSummaryItem key={doc.id} document={doc} />
              </Timeline.Item>
              ))}
            </Timeline>
          </div>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ClaimSummaryPanel.contextType = ClaimContext;

export default ClaimSummaryPanel;
