import _ from 'lodash';
import * as yup from 'yup';
import axios from 'axios';
import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import MaskedInput from 'react-text-mask';
import {
  Button, Divider, Radio, Input,
} from 'antd';
import styled from 'styled-components';
import ClaimContext from '../claim_context';

import LegalAgreement from '../legal_agreement';
import CompanyTermsDataModel from '../../content/CompanyTermsDataModel.json';

const RadioGroup = Radio.Group;

const StyledInput = styled.div`
  margin: 0 2px 8px 0;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;
const findButton = {
  margin: '8px 0 0 0',
};

class ValidateAccountByPhoneForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneResults: false,
    };
    this.handleStepForward = this.handleStepForward.bind(this);
    this.handleSelectPhone = this.handleSelectPhone.bind(this);
  }

  handleSelectPhone(e) {
    const guid = e.target.value;
    this.context.setAccountFound(true);
    this.context.setGuidId(guid);
    this.context.setCompanyCode(guid.substring(0, 2));
  }

  handleStepForward() {
    this.context.setStep(1);
  }

  renderPhoneResults() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    const accounts = this.state.accountsFound;

    if (accounts.length > 0) {
      const rows = [];
      for (let i = 0; i < accounts.length; i++) {
        // rows.push(<Option key={account[i]} value={account[i]}>{account[i]}</Option>);
        rows.push(
          <Radio style={radioStyle} value={accounts[i].guid}>
            {accounts[i].address.line1}
            {' '}
            {accounts[i].address.city}
            {' '}
            {accounts[i].address.zipCode}
          </Radio>,
        );
      }

      return (
        <Grid>
          <Row>
            <Col xs>
              <br />
              <h6>We found accounts related to that phone number. Please select your account below:</h6>
            </Col>
          </Row>
          <RadioGroup onChange={this.handleSelectPhone}>{rows}</RadioGroup>
        </Grid>
      );
    }

    return (
      <Grid>
        <Row>
          <Col xs>
            <br />
            <h6>We did not find any accounts with that phone.</h6>
            <p>Please call our Customer Operations Center:</p>
            <p>(800) 672-2231</p>
          </Col>
        </Row>
      </Grid>
    );
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              phoneNumber: '',
            }}
            onSubmit={async (values, actions) => {
              try {
                const endpoint = `${process.env.API_URL}/searchaccount`;
                const response = await axios.post(endpoint, {
                  phoneNumber: values.phoneNumber,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  searchBy: 'phone',
                });

                // Set the state to reflect that results have been found
                this.setState({
                  phoneResults: true,
                  accountsFound: response.data.accounts,
                });

                this.context.setGuidId(response.data.accounts[0].guid);
                actions.setSubmitting(false);
                // this.context.setAccountFound(true);

                const { guid } = response.data.accounts[0];
                this.context.setCompanyCode(guid.substring(0, 2));
                this.context.setCustomerInformation(values);

                const account = response.data.accounts[0];
                // Get the agreement based on users company code and state
                const legalDoc = _.find(CompanyTermsDataModel, {
                  companyCode: this.context.state.companyCode,
                  state: account.address.stateCode,
                });
                this.context.setCustomerLegal(legalDoc);
              } catch (error) {
                this.context.setAccountFound(false);
                actions.setSubmitting(false);
                this.setState({
                  phoneResults: true,
                  accountsFound: [],
                });
                // this.context.setCallCustomerSupport(true);
              }
            }}
            validationSchema={yup.object().shape({
              firstName: yup.string().required('First Name required'),
              lastName: yup.string().required('Last Name required'),
              phoneNumber: yup
                .string()
                .required('Please enter an account number.'),
            })}

          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form id="form_validate_account_phone">
                <Grid>
                  <Row>
                    <Col xs>
                      <p>Enter the name associated with your account. </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.firstName && errors.firstName && (
                      <FormIssue>{errors.firstName}</FormIssue>
                      )}
                      <Field
                        name="firstName"
                        id="firstName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="First Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                    <Col xs>
                      {touched.lastName && errors.lastName && (
                      <FormIssue>{errors.lastName}</FormIssue>
                      )}
                      <Field
                        name="lastName"
                        id="lastName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="Last Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <p>Enter Your Phone Number: </p>
                      <div>
                        {touched.phoneNumber && errors.phoneNumber && (
                        <FormIssue>{errors.phoneNumber}</FormIssue>
                        )}
                        {/* <Field  placeholder="Account Number" /> */}
                        <Field
                          type="phoneNumber"
                          name="phoneNumber"
                        >
                          {({ field /* _form */ }) => (
                            <MaskedInput
                              {...field}
                              className="custom_input"
                              id="phoneNumber"
                              placeholder="Phone Number"
                              guide={false}
                              style={{
                                padding: '6px 11px',
                                height: '40px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                border: '1px solid #d9d9d9',
                                transition: 'all .3s',
                              }}
                              mask={[
                                '(',
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ')',
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <Button
                        style={findButton}
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                      >
                        Find Account
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {this.state.phoneResults ? this.renderPhoneResults() : null}

                      {/* { context.state.accountFound ? <h5><Badge color="success"> Account Found </Badge></h5> : null } */}
                      <Divider />
                      {context.state.accountFound ? <LegalAgreement /> : null}
                      {context.state.accountFound ? (
                        <p>
                          <Button
                            type="primary"
                            size="large"
                            onClick={this.handleStepForward}
                          >
                            {' '}
                            Continue To File a Claim
                            {' '}
                          </Button>
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ValidateAccountByPhoneForm.contextType = ClaimContext;

export default ValidateAccountByPhoneForm;
