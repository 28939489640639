import _ from 'lodash';
import axios from 'axios';
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { NumericFormat, PatternFormat } from 'react-number-format';
import {
  Tooltip,
  Modal,
  Button,
  Upload,
  Input,
  message,
  Popconfirm,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';

import ClaimContext from '../claim_context';

// styled-component for the form (change color on validate)
const StyledInput = styled.div`
  margin: 0 2px 6px 0;
  width: 100%;
`;
const SectionTitle = styled.h6`
  margin: 10px 4px 4px 0;
  color: #424242;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

class ProductUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaderFileList: this.props.document.fileList,
    };

    this.renderFieldConfigs = this.renderFieldConfigs.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.getUploadEndpoint = this.getUploadEndpoint.bind(this);
    this.checkUpload = this.checkUpload.bind(this);
    this.handleUploadChange = this.handleUploadChange.bind(this);
    this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
  }

  changeValue(value, name) {
    const document = {
      ...this.props.document,
    };
    document[name] = value;
    this.props.change(document);
  }

  getUploadEndpoint() {
    const uploadEndpoint = `${process.env.API_URL}/attachment/`;
    return uploadEndpoint + this.props.guid;
  }

  renderUploadButton() {
    return (
      <span>
        <PlusOutlined style={{ fontSize: '36px' }} />
        <div className="ant-upload-text" style={{ marginTop: '10px' }}>
          Click to upload (15 MB max file size)
        </div>
      </span>
    );
  }

  checkUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 15;
    if (!isLt1M) {
      Modal.error({
        title: 'Validation Error',
        content: 'File must smaller than 15MB.',
      });
      this.context.setCurrentDocumentUpload([]);
      this.setState({
        fileList: [],
      });
    }
    return isLt1M;
  }

  handleUploadChange(info) {
    if (!this.checkUpload(info.file)) {
      return;
    }
    this.setState({
      uploaderFileList: info.fileList,
    });
    const { status } = info.file;

    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      this.setState({
        uploaderFileList: info.fileList,
      });

      this.changeValue(info.file.response, 'id');
      this.changeValue(info.file.thumbUrl, 'fileThumbUrl');
      this.changeValue(info.file.type, 'fileType');
      this.changeValue(info.file.name, 'fileName');
      this.changeValue(info.file, 'fileListItem');
      this.changeValue(info.fileList, 'fileList');
    } else if (status === 'error') {
      this.setState({
        uploaderFileList: [],
      });
      Modal.error({
        title: 'Validation Error',
        content:
          `${info.file.name
          } file upload failed. Either file size is over the limit allowed or file type is not allowed.`,
      });
    }
  }

  handleDeleteDocument() {
    const docId = this.props.document.id;
    try {
      if (docId) {
        const endpoint = `${process.env.API_URL}/attachment/${this.props.guid}/${docId}`;
        const response = axios.delete(endpoint);
        response.then((response) => {
          message.success('File removed successfully.');
        });
        response.catch((error) => {
          message.error('File was not deleted. Please try again.');
        });
        this.props.delete(this.props.document.index);
      } else {
        this.props.delete(this.props.document.index);
        return;
      }
    } catch (error) {
      console.log(error);
      message.error('File was not deleted. Please try again.');
    }
  }

  handleRemoveFile(e) {
    const docId = e.response;

    try {
      const endpoint = `${process.env.API_URL}/attachment/${this.props.guid}/${docId}`;
      axios.delete(endpoint);
      message.success('File removed successfully.');
      this.setState({
        fileList: [],
      });

      const document = {
        ...this.props.document,
      };
      document.id = '';
      document.fileThumbUrl = '';
      document.fileType = '';
      document.fileName = '';
      document.fileListItem = '';
      this.props.change(document);
    } catch (error) {
      console.log(error);
      message.error('File was not deleted. Please try again.');
    }
  }

  renderFieldConfigs() {
    const fields = {
      MAKE: (
        <StyledInput>
          <SectionTitle>Item Make/Brand </SectionTitle>
          <Input
            size="large"
            placeholder="Examples: Ford, Cuisinart"
            maxLength="50"
            name="productMake"
            onChange={(e) => this.changeValue(e.target.value, 'productMake')}
            value={this.props.document.productMake}
          />
        </StyledInput>
      ),
      MODEL: (
        <StyledInput>
          <SectionTitle>Item Model </SectionTitle>
          <Input
            size="large"
            placeholder="Examples: F-150, TL1000"
            maxLength="50"
            name="productModel"
            onChange={(e) => this.changeValue(e.target.value, 'productModel')}
            value={this.props.document.productModel}
          />
        </StyledInput>
      ),
      YEAR: (
        <StyledInput>
          <SectionTitle>
            Year Purchased
            {' '}
            <FormIssue style={{ display: 'inline-block' }}>
              &nbsp;
              {' '}
              {this.props.errors ? this.props.errors.yearPurchased : ''}
            </FormIssue>
          </SectionTitle>
          <PatternFormat
            className="custom_input"
            name="yearPurchased"
            placeholder="Example: 2009"
            format="####"
            style={{
              width: '100%',
              padding: '6px 11px',
              height: '40px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '1px solid #d9d9d9',
              transition: 'all .3s',
              display: 'block',
            }}
            onChange={(e) => this.changeValue(e.target.value, 'yearPurchased')}
            value={this.props.document.yearPurchased}
          />
        </StyledInput>
      ),
      PURCHASE_VALUE: (
        <StyledInput>
          <SectionTitle>
            Estimated Value
            {' '}
            <FormIssue style={{ display: 'inline-block' }}>
              {' '}
              &nbsp;
              {' '}
              {this.props.errors ? this.props.errors.estimatedValue : ''}
              {' '}
            </FormIssue>
          </SectionTitle>
          <NumericFormat
            thousandSeparator
            className="custom_input"
            prefix="$"
            placeholder="Example: $350"
            maxLength="11"
            name="estimatedValue"
            style={{
              width: '100%',
              padding: '6px 11px',
              height: '40px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '1px solid #d9d9d9',
              transition: 'all .3s',
              display: 'block',
            }}
            onChange={(e) => {
              e.target.value ? this.changeValue(
                numeral(e.target.value).value(),
                'estimatedValue',
              ) : this.changeValue(e.target.value, 'estimatedValue');
            }}
            value={this.props.document.estimatedValue}
          />
        </StyledInput>
      ),
    };

    const formFields = [];

    try {
      _.each(this.props.fieldConfig, (f) => {
        formFields.push(fields[f]);
      });
    } catch (e) {
      console.log(e);
    }

    if (this.props.fieldConfig != null) {
      return (
        <>
          <Row>
            <Col xs>
              {this.props.fieldConfig.indexOf('MAKE') !== -1 ? (
                <StyledInput>
                  <SectionTitle>Item Make/Brand </SectionTitle>
                  <Input
                    size="large"
                    placeholder="Examples: Ford, Cuisinart"
                    maxLength="50"
                    name="productMake"
                    onChange={(e) => this.changeValue(e.target.value, 'productMake')}
                    value={this.props.document.productMake}
                  />
                </StyledInput>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs>
              {this.props.fieldConfig.indexOf('MODEL') !== -1 ? (
                <StyledInput>
                  <SectionTitle>Item Model </SectionTitle>
                  <Input
                    size="large"
                    placeholder="Examples: F-150, TL1000"
                    maxLength="50"
                    name="productModel"
                    onChange={(e) => this.changeValue(e.target.value, 'productModel')}
                    value={this.props.document.productModel}
                  />
                </StyledInput>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs>
              {this.props.fieldConfig.indexOf('YEAR') !== -1 ? (
                <StyledInput>
                  <SectionTitle>
                    Year Purchased
                    {' '}
                    <FormIssue style={{ display: 'inline-block' }}>
                      &nbsp;
                      {' '}
                      {this.props.errors ? this.props.errors.yearPurchased : ''}
                    </FormIssue>
                  </SectionTitle>
                  <PatternFormat
                    className="custom_input"
                    name="yearPurchased"
                    placeholder="Example: 2009"
                    format="####"
                    style={{
                      width: '100%',
                      padding: '6px 11px',
                      height: '40px',
                      fontSize: '16px',
                      borderRadius: '4px',
                      border: '1px solid #d9d9d9',
                      transition: 'all .3s',
                      display: 'block',
                    }}
                    onChange={(e) => this.changeValue(e.target.value, 'yearPurchased')}
                    value={this.props.document.yearPurchased}
                  />
                </StyledInput>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs>
              {this.props.fieldConfig.indexOf('PURCHASE_VALUE') !== -1 ? (
                <StyledInput>
                  <SectionTitle>
                    Estimated Value
                    {' '}
                    <FormIssue style={{ display: 'inline-block' }}>
                      {' '}
                      &nbsp;
                      {' '}
                      {this.props.errors ? this.props.errors.estimatedValue : ''}
                      {' '}
                    </FormIssue>
                  </SectionTitle>
                  <NumericFormat
                    thousandSeparator
                    className="custom_input"
                    prefix="$"
                    placeholder="Example: $350"
                    maxLength="11"
                    name="estimatedValue"
                    style={{
                      width: '100%',
                      padding: '6px 11px',
                      height: '40px',
                      fontSize: '16px',
                      borderRadius: '4px',
                      border: '1px solid #d9d9d9',
                      transition: 'all .3s',
                      display: 'block',
                    }}
                    onChange={(e) => {
                      e.target.value ? this.changeValue(
                        numeral(e.target.value).value(),
                        'estimatedValue',
                      ) : this.changeValue(e.target.value, 'estimatedValue');
                    }}
                    value={this.props.document.estimatedValue}
                  />
                </StyledInput>
              ) : null}
            </Col>
          </Row>
        </>
      );
    }

    return (<div />);
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <Formik>
            <Form id="form_doc_product_upload" style={{ border: '1px solid #bbbbbb', marginBottom: '20px' }}>
              <Grid>
                <Row>
                  <Col xs>
                    <span name="s_fileName">{this.props.document.fileName}</span>
                  &nbsp;
                    <FormIssue style={{ display: 'inline-block' }}>
                      {' '}
                      {this.props.errors ? this.props.errors.id : ''}
                    </FormIssue>
                  </Col>
                  <Col xs>
                    <div
                      style={{
                        marginBottom: '5px',
                        marginTop: '5px',
                        float: 'right',
                      }}
                    >
                      <Popconfirm
                        title="Are you sure to delete this document?"
                        onConfirm={this.handleDeleteDocument}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title="Delete document">
                          <Button
                            type="default"
                            hidden={this.props.disableDelete}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              style={{
                                marginRight: '4px',
                                fontSize: '12px',
                                width: '15px',
                              }}
                            />
                            {' '}
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs>
                    <Upload
                      multiple={false}
                      name="file"
                      formId="1"
                      listType="picture-card"
                      fileList={this.state.uploaderFileList}
                      onChange={this.handleUploadChange}
                      onRemove={this.handleRemoveFile}
                      action={this.getUploadEndpoint}
                      style={{ width: '400px' }}
                    >
                      {this.state.uploaderFileList.length >= 1 ? null : this.renderUploadButton()}
                    </Upload>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <StyledInput>
                      <SectionTitle>
                        Title of Picture Upload (50 max)
                        {' '}
                        <FormIssue style={{ display: 'inline-block' }}>
                        &nbsp;
                          {' '}
                          {this.props.errors ? this.props.errors.documentTitle : ''}
                        </FormIssue>
                      </SectionTitle>
                      <Input
                        size="large"
                        name="documentTitle"
                        maxLength={50}
                        style={{ width: '100%' }}
                        onChange={(e) => this.changeValue(e.target.value, 'documentTitle')}
                        value={this.props.document.documentTitle}
                      />
                    </StyledInput>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <StyledInput>
                      <SectionTitle>
                        Description of Photo Item (50 max)
                        {' '}
                        <FormIssue style={{ display: 'inline-block' }}>
                        &nbsp;
                          {' '}
                          {this.props.errors ? this.props.errors.photoDescription : ''}
                        </FormIssue>
                      </SectionTitle>
                      <Input
                        size="large"
                        maxLength="50"
                        style={{ width: '100%' }}
                        name="photoDescription"
                        onChange={(e) => this.changeValue(e.target.value, 'photoDescription')}
                        value={this.props.document.photoDescription}
                      />
                    </StyledInput>
                  </Col>
                </Row>

                {this.renderFieldConfigs()}

              </Grid>
            </Form>
          </Formik>
        )}
      </ClaimContext.Consumer>
    );
  }
}

export default ProductUploadForm;
