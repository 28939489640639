import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {
  Modal, Upload, Input, message,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';

// styled-component for the form (change color on validate)
const StyledInput = styled.div`
  margin: 0 2px 6px 0;
  width: 100%;
`;
const SectionTitle = styled.h6`
  margin: 10px 4px 4px 0;
  color: #424242;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

class FoodItemUploadForm extends Component {
  constructor(props) {
    super(props);
    if (this.props.data.fileListItem) {
      this.state = {
        uploaderFileList: [this.props.data.fileListItem],
        fileList: [this.props.data.fileListItem],
      };
    } else {
      this.state = {
        uploaderFileList: [],
        fileList: [],
      };
    }
    this.getUploadEndpoint = this.getUploadEndpoint.bind(this);
    this.handleUploadChange = this.handleUploadChange.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.addUpload = this.addUpload.bind(this);
  }

  getUploadEndpoint() {
    const uploadEndpoint = `${process.env.API_URL}/attachment/`;
    return uploadEndpoint + this.props.guid;
  }

  addUpload() {

  }

  async handleRemoveFile(e) {
    const docId = e.response;
    try {
      const endpoint = `${process.env.API_URL
      }/attachment/${
        this.props.guid
      }/${
        docId}`;
      const response = await axios.delete(endpoint);
      message.success('File removed successfully.');

      const docData = {
        documentTitle: '',
        id: 0,
        photoDescription: '',
      };

      this.props.change(docData, 'id');
      this.setState({
        uploaderFileList: [],
        fileList: [],
      });
    } catch (error) {
      console.log(error);
      message.error('File was not deleted. Please try again.');
    }
  }

  checkUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < 15;
    if (!isLt1M) {
      Modal.error({
        title: 'Validation Error',
        content: 'File must smaller than 15MB.',
      });
      this.setState({
        uploaderFileList: [],
        fileList: [],
      });
    }
    return isLt1M;
  };

  handleUploadChange(info) {
    if (!this.checkUpload(info.file)) {
      return;
    }

    this.setState({
      uploaderFileList: info.fileList,
    });

    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      this.setState({
        fileList: info.fileList,
      });

      const doc = {
        ...this.props.data,
      };

      const { fileList } = this.state;
      doc.id = info.file.response;
      doc.fileThumbUrl = this.state.fileList[0].thumbUrl;
      doc.fileType = this.state.fileList[0].type;
      doc.fileName = this.state.fileList[0].name;
      doc.fileListItem = this.state.fileList[0];

      this.props.change(doc, 'id');
    } else if (status === 'error') {
      Modal.error({
        title: 'Validation Error',
        content: `${info.file.name} file upload failed. Either file size is over the limit allowed or file type is not allowed`,
      });
      this.setState({
        uploaderFileList: [],
        fileList: [],
      });
    }
  }

  changeValue = (e, name) => {
    const doc = {
      ...this.props.data,
    };
    doc[name] = e.target.value;
    this.props.change(doc, name);
  };

  renderUploadButton() {
    return (
      <span>
      
        <PlusOutlined />
        <div className="ant-upload-text">Click to upload (Max 15MB)</div>
      </span>
    );
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs s_fileName>
            <span name="s_fileName">{this.props.data.fileName}</span>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Upload
              multiple={false}
              name="file"
              formId="1"
              listType="picture-card"
              fileList={this.state.uploaderFileList}
              onChange={this.handleUploadChange}
              onRemove={this.handleRemoveFile}
              action={this.getUploadEndpoint}
            >
              {this.state.fileList.length >= 1 ? null : this.renderUploadButton()}

            </Upload>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <StyledInput>
              <SectionTitle>Title of Document/Picture Upload</SectionTitle>
              <Input
                name="documentTitle"
                size="large"
                maxLength="50"
                onChange={(e) => this.changeValue(e, 'documentTitle')}
                style={{ width: '100%' }}
                value={this.props.data.documentTitle}
              />
            </StyledInput>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <StyledInput>
              <SectionTitle>Description of Uploaded Items (50 Characters)</SectionTitle>
              <Input
                name="photoDescription"
                size="large"
                maxLength="50"
                onChange={(e) => this.changeValue(e, 'photoDescription')}
                style={{ width: '100%' }}
                value={this.props.data.photoDescription}
              />
            </StyledInput>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FoodItemUploadForm;
