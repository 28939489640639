import * as yup from 'yup';
import axios from 'axios';
import { navigate } from 'gatsby';
import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  Alert,
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Tooltip,
  Divider,
} from 'antd';
import MaskedInput from 'react-text-mask';
import { faAngleLeft, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import moment from 'moment';

import ClaimContext from '../claim_context';

const { Option } = Select;
const { TextArea } = Input;

// styled-component for the form (change color on validate)
const StyledInput = styled.div`
  margin: 0 2px 8px 0;
`;
const SectionTitle = styled.h5`
  margin: 10px 4px 4px 0;
  color: #424242;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

class ClaimSummaryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittingClaim: false,
      showOrderNumber: false,
    };
    this.handleStepForward = this.handleStepForward.bind(this);
    this.handleStepBack = this.handleStepBack.bind(this);
    this.handleTermsAgreement = this.handleTermsAgreement.bind(this);
    // this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleSubmitClaim = () => {
    this.setState({
      submittingClaim: true,
    });

    const date = this.context.state.incident.date.format('YYYY-MM-DD');
    const time = this.context.state.incident.time.format('hh:mm a');
    const dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm a').format();

    let modifiedFoodItem = this.context.state.foodItemList;
    if (modifiedFoodItem && modifiedFoodItem.length > 0) {
      modifiedFoodItem = modifiedFoodItem.map((item) => {
        item.quantity = item.quantity.toFixed(2);
        item.unitPrice = item.unitPrice.toFixed(2);
        return item;
      });
    }

    let { documentList } = this.context.state;
    if (documentList && documentList.length > 0) {
      documentList = documentList.map((doc) => {
        delete doc.fileListItem.thumbUrl;
        delete doc.fileThumbUrl;
        if (doc.estimatedValue) doc.estimatedValue = doc.estimatedValue.toFixed(2);
        return doc;
      });
    }
    // Take all the data and submit the claim
    const claim = {
      causeCode: this.context.state.incident.causeCode,
      claimComments: this.context.state.claimComments,
      customerAddress: {
        city: this.context.state.incident.city,
        houseNumber: '',
        line1: this.context.state.incident.line1,
        line2: this.context.state.incident.line2,
        stateCode: this.context.state.incident.stateCode,
        zipCode: this.context.state.incident.zipCode,
      },
      damageDate: dateTime,
      howDamagedCode: this.context.state.incident.howDamagedCode,
      documentList,
      foodItemList: modifiedFoodItem,
      email: this.context.state.customer.email,
      firstName: this.context.state.customer.firstName,
      guid: this.context.state.guid,
      incidentAddress: {
        city: this.context.state.incident.city,
        houseNumber: '',
        line1: this.context.state.incident.line1,
        line2: this.context.state.incident.line2,
        stateCode: this.context.state.incident.stateCode,
        zipCode: this.context.state.incident.zipCode,
      },
      lastName: this.context.state.customer.lastName,
      phoneNumber: this.context.state.customer.phoneNumber,
      phoneType: this.context.state.customer.phoneType,
      whatDamagedCode: this.context.state.incident.whatDamagedCode,
      remarks: this.context.state.incident.remarks,
    };

    try {
      const endpoint = `${process.env.API_URL}/claim`;
      const response = axios.post(endpoint, claim);

      response.then((response) => {
        try {
          const finalSubmitEndpoint = `${process.env.API_URL}/claim/submit/${this.context.state.guid}`;
          const final = axios.post(finalSubmitEndpoint, {
            guiId: this.context.state.guid,
          });

          final.then((finalResp) => {
            this.context.setOrderNumber(` ${finalResp.data.orderNo}`);
            this.setState({
              showOrderNumber: true,
              submittingClaim: false,
              orderNumberMsg:
                `Claim has been recieved. Your reference number is: ${this.context.state.orderNumber}`,
            });

            // dataLayer event push for form complete
            // dataLayer.push({
            //   event: 'formComplete'
            // });
          });

          final.catch((error) => {
            this.showErrModel();
          });
        } catch (error) {
          this.showErrModel();
        }
      });

      response.catch((error) => {
        this.showErrModel();
      });
    } catch (error) {
      this.showErrModel();
    }
  };

  showErrModel = () => {
    // dataLayer event push for form complete
    // dataLayer.push({
    //   event: 'formCompleteFailure'
    // });
    Modal.error({
      title: 'There was a problem submitting your claim',
      content: 'Please contact AEP for additional information about your claim submission. Call the Customer Operations Center: (800) 672-2231',
    });
  };

  componentDidMount() {
    this.context.setDisableClaimSubmission(true);
  }

  handleStepForward() {

  }

  handleStepBack() {
    this.context.setStep(3);
  }

  handleTermsAgreement(e) {
    if (e.target.checked) {
      this.context.setDisableClaimSubmission(false);
    }
    if (!e.target.checked) {
      this.context.setDisableClaimSubmission(true);
    }
  }

  renderSubmitButton(handleSubmit) {
    const text = 'Please agree to the terms and conditions before submitting.';
    if (this.context.state.disableClaimSubmission) {
      return (
        <div>
          <Tooltip placement="topLeft" title={text}>
            <Button
              name="fileClaim"
              disabled
              type="primary"
              style={{ float: 'right' }}
              onClick={handleSubmit}
            >
              Submit & File Claim
              {' '}
              <FontAwesomeIcon
                icon={faUpload}
                style={{ marginLeft: '4px', fontSize: '16px' }}
              />
            </Button>
          </Tooltip>
        </div>
      );
    }

    return (
      <div>
        <Button
          loading={this.state.submittingClaim}
          disabled={false}
          type="primary"
          style={{ float: 'right' }}
          onClick={handleSubmit}
        >
          Submit & File Claim
          {' '}
          <FontAwesomeIcon
            icon={faUpload}
            style={{ marginLeft: '4px', fontSize: '16px' }}
          />
        </Button>
      </div>
    );
  }

  handleOk() {
    // window.location.replace("https://www.aep.com/");
    navigate('/');
  }

  handleCancel() {
    this.setState({
      showOrderNumber: false,
    });
  }

  //   validationSchema={yup.object().shape({
  // 	agreeToTerms: yup.string().required("You must agree to the Terms & Conditions to submit claim."),
  //   })
  // }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Formik
            initialValues={{
              claimComments: this.context.state.claimComments,
            }}
            onSubmit={(values, actions) => {
              // Set the state to reflect that results have been found
              this.context.setClaimComments(values.claimComments);

              this.handleSubmitClaim();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              submitForm,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form id="form_claim_summary_submit">
                <Grid>
                  <Row>
                    <Col xs>
                      <SectionTitle>
                        Any other additional or concluding comments you want to
                        share?
                      </SectionTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.claimComments && errors.claimComments && (
                        <FormIssue>{errors.claimComments}</FormIssue>
                      )}
                      <TextArea
                        name="claimComments"
                        id="claimComments"
                        rows={6}
                        maxLength={250}
                        onChange={(e) => setFieldValue('claimComments', e.currentTarget.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <SectionTitle>Certify Claim</SectionTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <Checkbox
                        name="TermsAgreement"
                        onChange={this.handleTermsAgreement}
                      >
                        I certify that all information provided as part of this
                        application is true and correct to the best of my
                        knowledge.
                      </Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <Button type="primary" onClick={this.handleStepBack}>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          style={{ marginRight: '4px', fontSize: '16px' }}
                        />
                        {' '}
                        Back
                      </Button>
                    </Col>
                    <Col xs>{this.renderSubmitButton(handleSubmit)}</Col>
                  </Row>
                  <Modal
                    title="Claim Submitted Successfully"
                    open={this.state.showOrderNumber}
                    closable={false}
                    maskClosable={false}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                      <Button
                        key="submit"
                        type="primary"
                        onClick={this.handleOk}
                      >
                        Return to AEP
                      </Button>,
                    ]}
                  >
                    <Alert message={this.state.orderNumberMsg} type="success" />
                    <p />
                    <p>
                      Thank you for submitting your claim through the AEP Damage
                      Claims portal.
                    </p>
                    <p>
                      You will be receiving an email with a copy of your
                      reference number.
                    </p>
                    <p>
                      We have received your information and are in the process
                      of assigning your claim to one of our adjusters who will
                      get in touch with you soon.
                    </p>
                    <p>You can close this window and return to AEP.</p>
                    <p>AEP Risk and Insurance Management.</p>
                  </Modal>
                </Grid>
              </Form>
            )}</Formik>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ClaimSummaryForm.contextType = ClaimContext;

export default ClaimSummaryForm;
