import React, { Component } from 'react';
import { Button, Modal } from 'antd';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import aepOhio from '../legal_pdfs/AEP_OH.pdf';
import apcoVA from '../legal_pdfs/AppalachianPowerCompany_VA.pdf';
import kgPCo_TN from '../legal_pdfs/KgPCo_TN.pdf';
import apco_WV from '../legal_pdfs/AppalachianPowerCompany_WV.pdf';
import KentuckyPowerCompany_KY from '../legal_pdfs/KentuckyPowerCompany_KY.pdf';
import Indiana_IN from '../legal_pdfs/Indiana_IN.pdf';
import IM_MI from '../legal_pdfs/I&M_MI.pdf';
import AEPTexasNorthDivision_TX from '../legal_pdfs/AEPTexasNorthDivision_TX.pdf';
import PSODenial_OK from '../legal_pdfs/PSODenial_OK.pdf';
import Arkansas_AR from '../legal_pdfs/Arkansas_AR.pdf';
import LouisianaB_LA from '../legal_pdfs/LouisianaB_LA.pdf';
import SWEPCO_TX from '../legal_pdfs/SWEPCO_TX.pdf';

import ClaimContext from './claim_context';

class LegalAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAgreement: false,
    };
    this.handleShowAgreement = this.handleShowAgreement.bind(this);
    this.handleCloseAgreement = this.handleCloseAgreement.bind(this);
  }

  handleShowAgreement() {
    this.setState({
      showAgreement: true,
    });
  }

  handleCloseAgreement() {
    this.setState({
      showAgreement: false,
    });
  }

  getPdf = contextFilename => {
    if (!contextFilename) return null;

    const pdfs = [apcoVA, aepOhio, kgPCo_TN, apco_WV, KentuckyPowerCompany_KY, Indiana_IN, IM_MI, AEPTexasNorthDivision_TX, PSODenial_OK, Arkansas_AR, LouisianaB_LA, SWEPCO_TX];
    for (let i = 0; i < pdfs.length; i++) {
      const test = contextFilename.split('.')[0];
      const pdf = pdfs[i];
      if (pdf.includes(test)) {
        return pdf;
      }
    }
    return '';
  };

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <div>
            <p />
            <p>
              Click terms and conditions to review your terms and conditions of service.
              {' '}
              {/* eslint-disable-next-line */}

              <a href="#" onClick={this.handleShowAgreement}>{' '}Terms and conditions{' '}
                <FontAwesomeIcon
                  style={{ marginLeft: '5px' }}
                  icon={faExternalLinkAlt}
                  color="#4191F7"
                />
              </a>
              .
            </p>

            <Modal
              title="Terms & Conditions"
              open={this.state.showAgreement}
              onOk={this.handleCloseAgreement}
              onCancel={this.handleCloseAgreement}
              width="70%"
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleCloseAgreement}
                >
                  Close
                </Button>,
              ]}
            >
              <div style={{ height: '90vh' }}>
                <object
                  width="100%"
                  height="100%"
                  data={this.getPdf(this.context.state.legal.fileName)}
                  type="application/pdf"
                >
                  <embed
                    src={this.getPdf(this.context.state.legal.fileName)}
                    type="application/pdf"
                  />
                </object>
              </div>
            </Modal>
          </div>
        )}
      </ClaimContext.Consumer>
    );
  }
}

LegalAgreement.contextType = ClaimContext;

export default LegalAgreement;
