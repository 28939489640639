import _ from 'lodash';
import * as yup from 'yup';
import axios from 'axios';
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import {
  message,
  Button, DatePicker, TimePicker, Input, Select, Spin,
} from 'antd';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { LoadingOutlined } from '@ant-design/icons';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import styled from 'styled-components';

import ClaimContext from '../claim_context';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { TextArea } = Input;
const { Option } = Select;

// styled-component for the form (change color on validate)
const StyledInput = styled.div`
  margin: 0 2px 8px 0;
`;
const SectionTitle = styled.h6`
  margin: 10px 4px 4px 0;
  color: #424242;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const REMARKSMAX = 780;

function handleDatePicker(e, setFieldValue) {
  //  const date = e.toJSON();
  setFieldValue('date', e);
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}

class IncidentInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      damageTypes: [],
      // damageDescOptions: <Option value="na">Select Damage Type First</Option>,
      loadingDataModel: true,
      causeLabel: '',
      // showCause: false,
      submitted: false,
    };
    this.handleDamagedItemSelection = this.handleDamagedItemSelection.bind(this);
    this.handleStepForward = this.handleStepForward.bind(this);
    this.handleStepBack = this.handleStepBack.bind(this);
    this.handleRemarksCounter = this.handleRemarksCounter.bind(this);
    // this.handleDatePicker = this.handleDatePicker.bind(this);
    // this.disabledDate = this.disabledDate.bind(this);
  }

  async componentDidMount() {
    // Get any data needed for subsequent forms like incident data model.
    const endpoint = `${process.env.API_URL}/data`;
    const response = await axios.get(endpoint);
    this.context.setIncidentDataModel(response.data);
    // After setIncidentDataModel has been set remove loading state on selection fields
    // eslint-disable-next-line
    const damageTypes = this.context.state.incidentDataModel.map((incident) => ({ code: incident.code, type: incident.damageType }));
    this.setState({
      loadingDataModel: false,
      damageTypes,
    });
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.submitted === true) {
      this.handleStepForward();
      return false;
    }
    return true;
  }

  handleStepForward() {
    this.context.setStep(3);
  }

  handleStepBack() {
    this.context.setStep(1);
  }

  handleDamagedItemSelection(e, setFieldValue) {
    const value = e;
    if (this.context.state.incident.whatDamagedCode === this.context.state.foodItemDamageCode
        && value !== this.context.state.foodItemDamageCode) {
      this.context.setFoodItemList([]);
      this.context.setFoodItemIndex(0);
      this.context.setFoodItemTotal(0);

      try {
        _.each(this.context.state.documentList, () => {
        //  const endpoint = `${process.env.API_URL}/attachment/${this.context.state.guid}/${doc.id}`;
        //  const response = axios.delete(endpoint);
        });
        this.context.setDocumentList([]);
      } catch (error) {
        message.error('Unexpect error. Refresh page and fill in the details again', 10);
      }
    }

    setFieldValue('whatDamagedCode', value);
    this.context.setDamagedItem(value);

    setFieldValue('howDamagedCode', '');
    this.context.setDamagedDesc('');

    setFieldValue('causeCode', '');
    this.context.setShowCause(false);

    // Load up damage description items based on data model
    const filteredArr = this.context.state.incidentDataModel.filter(
      (item) => item.code === value,
    );
    const damageEvents = filteredArr[0].events;
    const damagedItemLabel = filteredArr[0].damageType;

    let { fieldConfig } = filteredArr[0];

    if (fieldConfig != null) {
      fieldConfig = fieldConfig.split(',');
      this.context.setDocumentFieldConfig(fieldConfig);
    } else {
      this.context.setDocumentFieldConfig([]);
    }

    this.setState({
      damagedItemLabel,
    });

    const options = [];
    for (let i = 0; i < damageEvents.length; i++) {
      options.push(
        <Option key={damageEvents[i].code} value={damageEvents[i].code}>
          {damageEvents[i].eventType}
        </Option>,
      );
    }

    this.context.setDamageDescOptions(options);
  }

  handleDamagedDescSelection(e, setFieldValue) {
    const value = e;
    setFieldValue('howDamagedCode', value);
    this.context.setDamagedDesc(value);

    setFieldValue('causeCode', '');
    this.context.setShowCause(false);

    // Load up damage description items based on data model
    const filteredTypes = this.context.state.incidentDataModel.filter(
      (item) => item.code === this.context.state.damagedItem,
    );
    const filteredEvents = filteredTypes[0].events.filter(
      (item) => item.code === value,
    );
    const eventData = filteredEvents[0];
    const damagedDescLabel = eventData.eventType;

    this.setState({
      damagedDescLabel,
    });

    // Check and see if any causes are defined for this damage event.
    // If so load the selection field with the cause selections
    if (typeof eventData.causes !== 'undefined' && eventData.causes.length > 0) {
      const options = [];
      for (let i = 0; i < eventData.causes.length; i++) {
        options.push(
          <Option
            key={eventData.causes[i].code}
            value={eventData.causes[i].code}
          >
            {eventData.causes[i].cause}
          </Option>,
        );
      }

      this.context.setShowCause(true);
      setFieldValue('showCause', true);

      this.context.setCausesOptions(options);
      this.context.setCauseLabel(eventData.causeLabel);
    } else {
      this.context.setShowCause(false);
      setFieldValue('showCause', false);
    }
  }

  handleCausesSelection(e, setFieldValue) {
    const value = e;
    setFieldValue('causeCode', value);

    // Load up damage description items based on data model
    // const filteredTypes = this.context.state.incidentDataModel.filter(item => item.code === this.context.state.damagedItem);
    // const filteredEvents = filteredTypes[0].events.filter(item => item.code === this.context.state.damagedDesc);
    // const eventData = filteredEvents[0];

    // Load up damage description items based on data model
    const filteredTypes = this.context.state.incidentDataModel.filter(
      (item) => item.code === this.context.state.damagedItem,
    );
    const filteredEvents = filteredTypes[0].events.filter(
      (item) => item.code === this.context.state.damagedDesc,
    );
    const eventData = filteredEvents[0];

    const filteredCauses = eventData.causes.filter(
      (item) => item.code === value,
    );

    const causeCodeLabel = filteredCauses[0].cause;

    this.setState({
      causeCodeLabel,
    });
  }

  handleRemarksCounter(e, setFieldValue) {
    const { value } = e.currentTarget;
    setFieldValue('remarks', value);
    const { length } = value;
    this.setState({
      remarksCount: length,
    });
  }

  renderDamageTypesSelect(value, setFieldValue) {
    const { damageTypes } = this.state;
    const options = [];
    for (let i = 0; i < damageTypes.length; i++) {
      options.push(
        <Option key={damageTypes[i].code} value={damageTypes[i].code}>
          {damageTypes[i].type}
        </Option>,
      );
    }
    return (
      <StyledInput>
        <Spin
          tip="Loading selections..."
          spinning={this.state.loadingDataModel}
          indicator={antIcon}
        >
          <Select
            name="whatDamagedCode"
            id="whatDamagedCode"
            size="large"
            showSearch
            onChange={(e) => this.handleDamagedItemSelection(e, setFieldValue)}
            placeholder="Select the damage type"
            optionFilterProp="children"
            value={value}
          >
            {options}
          </Select>
        </Spin>
      </StyledInput>
    );
  }

  // Render the damage description based on damageDescOptions state
  // onChange={e => setFieldValue('damagedDesc', e)}
  renderDamageDesc(value, setFieldValue) {
    return (
      <StyledInput>
        <Spin
          tip="Loading selections..."
          spinning={this.state.loadingDataModel}
          indicator={antIcon}
        >
          <Select
            name="howDamagedCode"
            id="howDamagedCode"
            size="large"
            onChange={(e) => this.handleDamagedDescSelection(e, setFieldValue)}
            showSearch
            placeholder="Select the type of event"
            optionFilterProp="children"
            disabled={false}
            value={value}
          >
            {this.context.state.damageDescOptions}
          </Select>
        </Spin>
      </StyledInput>
    );
  }

  renderCausesSelect(value, setFieldValue) {
    return (
      <StyledInput>
        <Spin
          tip="Loading selections..."
          spinning={this.state.loadingDataModel}
          indicator={antIcon}
        >
          <Select
            name="causeCode"
            id="causeCode"
            size="large"
            onChange={(e) => this.handleCausesSelection(e, setFieldValue)}
            showSearch
            placeholder="Select cause"
            optionFilterProp="children"
            disabled={false}
            value={value}
          >
            {this.context.state.causesOptions}
          </Select>
        </Spin>
      </StyledInput>
    );
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <Formik
            initialValues={{
              whatDamagedCode: this.context.state.incident.whatDamagedCode,
              howDamagedCode: this.context.state.incident.howDamagedCode,
              causeCode: this.context.state.incident.causeCode,
              date: this.context.state.incident.date,
              time: this.context.state.incident.time,
              remarks: this.context.state.incident.remarks,
              line1: this.context.state.incident.line1,
              line2: this.context.state.incident.line2,
              stateCode: this.context.state.incident.stateCode,
              city: this.context.state.incident.city,
              zipCode: this.context.state.incident.zipCode,
              showCause: this.context.state.showCause,
            }}
            validationSchema={yup.object().shape({
              whatDamagedCode: yup
                .string()
                .required('Choose What property was damaged.'),
              howDamagedCode: yup
                .string()
                .required('Choose How was your property damaged'),
              showCause: yup.boolean().default(true),
              causeCode: yup
                .string()
                .notRequired()
                .when('showCause', {
                  is: true,
                  then: () => yup.string().required('Must select a cause.'),
                  otherwise: () => yup.string().notRequired(),
                }),
              date: yup.string().required('Please select date.'),
              time: yup.string().required('Please select time.'),
              remarks: yup.string().required('Please Enter Description.'),
              line1: yup.string().required('Address Line is required.'),
              stateCode: yup.string().required('Choose State '),
              city: yup.string().required('City is required.'),
              zipCode: yup.string().required('Zip Code is required.'),
            })}
            onSubmit={(values) => {
              // Set the state to reflect that results have been found
              const updatedValues = {
                ...values,
                damagedItemLabel: this.state.damagedItemLabel,
                damagedDescLabel: this.state.damagedDescLabel,
                causeLabel: this.state.causeLabel,
                causeCodeLabel: this.state.causeCodeLabel,
              };
              this.context.setIncidentInformation(updatedValues);
              this.setState({ submitted: true });
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form id="form_incident_information">
                <Grid>
                  <Row>
                    <Col xs>
                      <Row>
                        <Col xs>
                          <SectionTitle>
                            What property was damaged?
                          </SectionTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.whatDamagedCode
                            && errors.whatDamagedCode && (
                              <FormIssue id="errWhatDamagedCode">{errors.whatDamagedCode}</FormIssue>

                          )}
                          {this.renderDamageTypesSelect(
                            values.whatDamagedCode,
                            setFieldValue,
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <SectionTitle>
                            How was your property damaged?
                          </SectionTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.howDamagedCode && errors.howDamagedCode && (
                            <FormIssue id="errHowDamagedCode">{errors.howDamagedCode}</FormIssue>
                          )}
                          {this.renderDamageDesc(
                            values.howDamagedCode,
                            setFieldValue,
                          )}
                        </Col>
                      </Row>

                      {this.context.state.showCause ? (
                        <div>
                          <Row>
                            <Col xs>
                              <SectionTitle>
                                {/* eslint-disable-next-line */}
                                {this.context.state.causeLabel ? this.context.state.causeLabel : 'Do you know if this was AEP or a Contractor'}
                                ?
                              </SectionTitle>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs>
                              {touched.causeCode && errors.causeCode && (
                                <FormIssue id="errCauseCode">{errors.causeCode}</FormIssue>
                              )}
                              {this.renderCausesSelect(
                                values.causeCode,
                                setFieldValue,
                              )}
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      <Row>
                        <Col xs>
                          <SectionTitle>When did it happen?</SectionTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.date && errors.date && (
                            <FormIssue id="errDate">{errors.date}</FormIssue>
                          )}
                          <DatePicker
                            id="date"
                            name="date"
                            disabledDate={disabledDate}
                            onChange={(e) => handleDatePicker(e, setFieldValue)}
                            size="large"
                            value={values.date}
                          />
                        </Col>
                        <Col xs>
                          {touched.time && errors.time && (
                            <FormIssue id="errTime">{errors.time}</FormIssue>
                          )}
                          <TimePicker
                            id="time"
                            name="time"
                            use12Hours
                            onChange={(e) => setFieldValue('time', e)}
                            size="large"
                            defaultOpenValue={moment('01:00 am', 'HH:mm a')}
                            format="hh:mm a"
                            minuteStep={15}
                            value={values.time}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <SectionTitle>
                            Where did this loss occur?
                          </SectionTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.line1 && errors.line1 && (
                            <FormIssue id="errLine1">{errors.line1}</FormIssue>
                          )}
                          <StyledInput>
                            <Input
                              name="line1"
                              id="addressline1"
                              size="large"
                              onChange={(e) => setFieldValue('line1', e.currentTarget.value)}
                              placeholder="Address Line"
                              value={values.line1}
                            />
                          </StyledInput>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.line2 && errors.line2 && (
                            <FormIssue id="errLine2">{errors.line2}</FormIssue>
                          )}
                          <StyledInput>
                            <Input
                              name="line2"
                              id="addressline2"
                              size="large"
                              onChange={(e) => setFieldValue('line2', e.currentTarget.value)}
                              placeholder="Address Line Cont. (if needed)"
                              value={values.line2}
                            />
                          </StyledInput>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.city && errors.city && (
                            <FormIssue id="errCity">{errors.city}</FormIssue>
                          )}
                          <StyledInput>
                            <Input
                              name="city"
                              id="city"
                              size="large"
                              onChange={(e) => setFieldValue('city', e.currentTarget.value)}
                              placeholder="City"
                              value={values.city}
                            />
                          </StyledInput>
                        </Col>
                        <Col xs>
                          {touched.stateCode && errors.stateCode && (
                            <FormIssue id="errStateCode">{errors.stateCode}</FormIssue>
                          )}
                          <StyledInput>
                            <Select
                              name="stateCode"
                              id="stateCode"
                              size="large"
                              onChange={(e) => setFieldValue('stateCode', e)}
                              showSearch
                              placeholder="Select a State"
                              optionFilterProp="children"
                              value={values.stateCode}
                            >
                              <Option value="AL">AL</Option>
                              <Option value="AK">AK</Option>
                              <Option value="AR">AR</Option>
                              <Option value="AZ">AZ</Option>
                              <Option value="CA">CA</Option>
                              <Option value="CO">CO</Option>
                              <Option value="CT">CT</Option>
                              <Option value="DC">DC</Option>
                              <Option value="DE">DE</Option>
                              <Option value="FL">FL</Option>
                              <Option value="GA">GA</Option>
                              <Option value="HI">HI</Option>
                              <Option value="IA">IA</Option>
                              <Option value="ID">ID</Option>
                              <Option value="IL">IL</Option>
                              <Option value="IN">IN</Option>
                              <Option value="KS">KS</Option>
                              <Option value="KY">KY</Option>
                              <Option value="LA">LA</Option>
                              <Option value="MA">MA</Option>
                              <Option value="MD">MD</Option>
                              <Option value="ME">ME</Option>
                              <Option value="MI">MI</Option>
                              <Option value="MN">MN</Option>
                              <Option value="MO">MO</Option>
                              <Option value="MS">MS</Option>
                              <Option value="MT">MT</Option>
                              <Option value="NC">NC</Option>
                              <Option value="NE">NE</Option>
                              <Option value="NH">NH</Option>
                              <Option value="NJ">NJ</Option>
                              <Option value="NM">NM</Option>
                              <Option value="NV">NV</Option>
                              <Option value="NY">NY</Option>
                              <Option value="ND">ND</Option>
                              <Option value="OH">OH</Option>
                              <Option value="OK">OK</Option>
                              <Option value="OR">OR</Option>
                              <Option value="PA">PA</Option>
                              <Option value="RI">RI</Option>
                              <Option value="SC">SC</Option>
                              <Option value="SD">SD</Option>
                              <Option value="TN">TN</Option>
                              <Option value="TX">TX</Option>
                              <Option value="UT">UT</Option>
                              <Option value="VT">VT</Option>
                              <Option value="VA">VA</Option>
                              <Option value="WA">WA</Option>
                              <Option value="WI">WI</Option>
                              <Option value="WV">WV</Option>
                              <Option value="WY">WY</Option>
                            </Select>
                          </StyledInput>
                        </Col>
                        <Col xs>
                          {touched.zipCode && errors.zipCode && (
                            <FormIssue id="errZipCode">{errors.zipCode}</FormIssue>
                          )}
                          <StyledInput>
                            <Input
                              name="zipCode"
                              id="zipCode"
                              size="large"
                              onChange={(e) => setFieldValue('zipCode', e.currentTarget.value)}
                              placeholder="Zip"
                              value={values.zipCode}
                            />
                          </StyledInput>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <SectionTitle>
                            Please provide a detailed description of your
                            incident
                          </SectionTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          {touched.remarks && errors.remarks && (
                            <FormIssue id="errRemarks">{errors.remarks}</FormIssue>
                          )}
                          <TextArea
                            name="remarks"
                            id="remarks"
                            rows={6}
                            maxLength={REMARKSMAX}
                            onChange={(e) => this.handleRemarksCounter(e, setFieldValue)}
                            value={values.remarks}
                          />
                          <span style={{ float: 'right' }}>
                            {this.state.remarksCount}
                            {' '}
                            /
                            {REMARKSMAX}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <Button type="primary" onClick={this.handleStepBack}>
                            <FontAwesomeIcon
                              icon={faAngleLeft}
                              style={{ marginRight: '4px', fontSize: '16px' }}
                            />
                            {' '}
                            Back
                          </Button>
                        </Col>
                        <Col xs>
                          <Button
                            type="primary"
                            style={{ float: 'right' }}
                            onClick={handleSubmit}
                          >
                            Save & Continue
                            {' '}
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              style={{ marginLeft: '4px', fontSize: '16px' }}
                            />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <input name="showCause" type="hidden" value="false" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </ClaimContext.Consumer>
    );
  }
}

IncidentInformation.contextType = ClaimContext;

export default IncidentInformation;
