import _ from 'lodash';
import * as yup from 'yup';
import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  Button, Input, Tooltip, InputNumber,
} from 'antd';
import styled from 'styled-components';
import { faAngleRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// styled-component for the form (change color on validate)
const StyledInput = styled.div`
  margin: 0 2px 0px 0;
  width: 100%;
`;
const SectionTitle = styled.h6`
  margin: 10px 4px 4px 0;
  color: #424242;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

class FoodItemForm extends Component {
  constructor(props) {
    super(props);
  }

  changeValue = (e, name) => {
    const item = {
      ...this.props.item,
    };
    item[name] = e.target.value;
    this.props.change(item, name);
  };

  changeNumValue = (e, name) => {
    const item = {
      ...this.props.item,
    };
    item[name] = e;
    this.props.change(item, name);
  };

  render() {
    return (

      <div>
        <div>
          <Row>
            <Col xs>
              <FormIssue>{this.props.errors ? this.props.errors.itemLost : ''}</FormIssue>
              <StyledInput>
                <Input name="itemLost" size="large" onChange={(e) => this.changeValue(e, 'itemLost')} placeholder="Ex: Milk" value={this.props.item.itemLost} />
              </StyledInput>
            </Col>
            <Col xs>
              <FormIssue>{this.props.errors ? this.props.errors.quantity : ''}</FormIssue>
              <StyledInput>
                <InputNumber
                  min={0}
                  size="large"
                  onChange={(e) => this.changeNumValue(e, 'quantity')}
                  placeholder="Ex: 2"
                  value={this.props.item.quantity}
                  name="quantity"
                  step="1"
                />
              </StyledInput>
            </Col>
            <Col xs>
              <FormIssue>{this.props.errors ? this.props.errors.unitPrice : ''}</FormIssue>
              <StyledInput>
                <InputNumber
                  min={0}
                  addonBefore="$"
                  name="unitPrice"
                  onChange={(e) => this.changeNumValue(e, 'unitPrice')}
                  size="large"
                  placeholder="Ex: 2.30"
                  value={this.props.item.unitPrice}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  step=".05"
                />
              </StyledInput>
            </Col>
            <Col xs>
              <FormIssue />
              <Tooltip title="Delete food item">
                <Button name="deleteFoodItem" type="default" onClick={this.props.delete} style={{ marginTop: '5px' }}>
                  <FontAwesomeIcon
                    icon={faTrash}

                    style={{
                      marginRight: '4px',
                      fontSize: '12px',
                      width: '15px',
                    }}
                  />
                  {' '}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: '15px' }} />
      </div>
    );
  }
}

export default FoodItemForm;
