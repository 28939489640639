import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Divider } from 'antd';

import DocumentationForm from '../forms/form_documentation';
import ClaimContext from '../claim_context';
import ClaimSummaryPanel from '../claim_summary_panel';

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.onbeforeunload = () => 'Are you sure you want to leave this page?  You will lose any unsaved data.';
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <Grid>
            <Row>
              <Col xs>
                <h3 style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
                  Documentation
                  {' '}
                  {' '}
                </h3>
                <Divider />
              </Col>
            </Row>
            <div style={{ minHeight: '360px' }}>
              <Row>
                <Col xs>
                  <DocumentationForm />
                </Col>
                <Col xs>
                  <ClaimSummaryPanel />
                </Col>
              </Row>
            </div>
          </Grid>
        )}
      </ClaimContext.Consumer>
    );
  }
}

Documentation.contextType = ClaimContext;

export default Documentation;
