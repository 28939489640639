import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import ClaimContext from '../claim_context';
import ClaimSummaryPanel from '../claim_summary_panel';
import ClaimSummarySubmitForm from '../forms/form_claim_summary_submit';

class ClaimSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.onbeforeunload = () => 'Are you sure you want to leave this page?  You will lose any unsaved data.';
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Grid>
            <Row>
              <Col xs={1} />
              <Col xs>
                {/* <Alert message={"Your claim has been successfuly submitted.Your order number is:  " + this.context.state.orderNumber } type="success" /> */}
                <br />
                <ClaimSummaryPanel context={context} />
              </Col>
              <Col xs={1} />
            </Row>
            <Row>
              <Col xs={1} />
              <Col xs>
                <ClaimSummarySubmitForm />
              </Col>
              <Col xs={1} />
            </Row>
            <div style={{ minHeight: '360px' }}>
              <Row>
                <Col xs />
              </Row>
              <Row>
                <Col xs />
              </Row>
            </div>
          </Grid>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ClaimSummary.contextType = ClaimContext;

export default ClaimSummary;
