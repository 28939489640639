import _ from 'lodash';
import * as yup from 'yup';
import axios from 'axios';
import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Button, Divider, Input, Radio, Select,
} from 'antd';
import { Grid, Row, Col } from 'react-flexbox-grid';

import styled from 'styled-components';
import ClaimContext from '../claim_context';

import LegalAgreement from '../legal_agreement';
import CompanyTermsDataModel from '../../content/CompanyTermsDataModel.json';

const RadioGroup = Radio.Group;
const { Option } = Select;

const StyledInput = styled.div`
  margin: 0 2px 8px 0;
`;

const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;
const findButton = {
  margin: '0 0 0 20px',
};

class ValidateAccountByAddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressResults: false,
      accountsFound: [],
    };
    this.handleStepForward = this.handleStepForward.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
  }

  handleSelectAddress(e) {
    this.context.setAccountFound(true);
    const guid = e.target.value;
    this.context.setGuidId(guid);
    this.context.setCompanyCode(guid.substring(0, 2));
  }

  handleStepForward() {
    this.context.setStep(1);
  }

  renderAddressResults() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    const accounts = this.state.accountsFound;

    if (accounts.length > 0) {
      const rows = [];
      for (let i = 0; i < accounts.length; i++) {
        // rows.push(<Option key={account[i]} value={account[i]}>{account[i]}</Option>);
        rows.push(
          <Radio style={radioStyle} value={accounts[i].guid}>
            {accounts[i].address.line1}
            {' '}
            {accounts[i].address.city}
            {' '}
            {accounts[i].address.zipCode}
          </Radio>,
        );
      }

      return (
        <Grid>
          <Row>
            <Col xs>
              <br />
              <p>
                We found accounts related to that address. Please select your
                address below:
              </p>
            </Col>
          </Row>
          <RadioGroup onChange={this.handleSelectAddress}>{rows}</RadioGroup>
        </Grid>
      );
    }

    return (
      <Grid>
        <Row>
          <Col xs>
            <br />
            <h6>We did not find any accounts with that address.</h6>
            <p>Please call our Customer Operations Center:</p>
            <p>(800) 672-2231</p>
          </Col>
        </Row>
      </Grid>
    );
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              address1: '',
              city: '',
              state: '',
              zip: '',
            }}
            onSubmit={async (values, actions) => {
              try {
                const endpoint = `${process.env.API_URL}/searchaccount`;
                const response = await axios.post(endpoint, {
                  addressLine1: values.address1,
                  city: values.city,
                  stateCode: values.state,
                  zip: values.zip,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  searchBy: 'address',
                });

                // Set the state to reflect that results have been found
                this.setState({
                  addressResults: true,
                  accountsFound: response.data.accounts,
                });

                this.context.setGuidId(response.data.accounts[0].guid);
                actions.setSubmitting(false);
                // this.context.setAccountFound(true);

                const { guid } = response.data.accounts[0];
                this.context.setCompanyCode(guid.substring(0, 2));
                this.context.setCustomerInformation(values);

                const account = response.data.accounts[0];
                // Get the agreement based on users company code and state
                const legalDoc = _.find(CompanyTermsDataModel, {
                  companyCode: this.context.state.companyCode,
                  state: account.address.stateCode,
                });
                this.context.setCustomerLegal(legalDoc);
              } catch (error) {
                this.context.setAccountFound(false);
                actions.setSubmitting(false);
                this.setState({
                  addressResults: true,
                  accountsFound: [],
                });
              }
            }}
            validationSchema={yup.object().shape({
              firstName: yup.string().required('First name required'),
              lastName: yup.string().required('Last name required'),
              address1: yup.string().required('Please enter an address.'),
              city: yup.string().required('Please enter a city.'),
              state: yup.string().required('Please enter a state.'),
              zip: yup.string().required('Please enter a zip code.'),
            })}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form id="form_validate_account_address">
                <Grid>
                  <Row>
                    <Col xs>
                      <p>Enter the name associated with your account: </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.firstName && errors.firstName && (
                      <FormIssue>{errors.firstName}</FormIssue>
                      )}
                      <Field
                        name="firstName"
                        id="firstName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="First Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                    <Col xs>
                      {touched.lastName && errors.lastName && (
                      <FormIssue>{errors.lastName}</FormIssue>
                      )}
                      <Field
                        name="lastName"
                        id="lastName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="Last Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <p>Enter your address to find your account: </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.address1 && errors.address1 && (
                      <FormIssue>{errors.address1}</FormIssue>
                      )}
                      <Field
                        name="address1"
                        id="address1"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="Address Line"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.address2 && errors.address2 && (
                      <FormIssue>{errors.address2}</FormIssue>
                      )}
                      <Field
                        name="address2"
                        id="address2"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="Address Line Cont. (if needed)"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.city && errors.city && (
                      <FormIssue>{errors.city}</FormIssue>
                      )}
                      <Field
                        name="city"
                        id="city"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input {...field} size="large" placeholder="City" />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                    <Col xs>
                      {touched.state && errors.state && (
                      <FormIssue>{errors.state}</FormIssue>
                      )}
                      <StyledInput>
                        <Select
                          name="state"
                          id="state"
                          size="large"
                          onChange={(e) => setFieldValue('state', e)}
                          placeholder="Select a State"
                          optionFilterProp="children"
                          showSearch
                        >
                          <Option value="AL">AL</Option>
                          <Option value="AK">AK</Option>
                          <Option value="AR">AR</Option>
                          <Option value="AZ">AZ</Option>
                          <Option value="CA">CA</Option>
                          <Option value="CO">CO</Option>
                          <Option value="CT">CT</Option>
                          <Option value="DC">DC</Option>
                          <Option value="DE">DE</Option>
                          <Option value="FL">FL</Option>
                          <Option value="GA">GA</Option>
                          <Option value="HI">HI</Option>
                          <Option value="IA">IA</Option>
                          <Option value="ID">ID</Option>
                          <Option value="IL">IL</Option>
                          <Option value="IN">IN</Option>
                          <Option value="KS">KS</Option>
                          <Option value="KY">KY</Option>
                          <Option value="LA">LA</Option>
                          <Option value="MA">MA</Option>
                          <Option value="MD">MD</Option>
                          <Option value="ME">ME</Option>
                          <Option value="MI">MI</Option>
                          <Option value="MN">MN</Option>
                          <Option value="MO">MO</Option>
                          <Option value="MS">MS</Option>
                          <Option value="MT">MT</Option>
                          <Option value="NC">NC</Option>
                          <Option value="NE">NE</Option>
                          <Option value="NH">NH</Option>
                          <Option value="NJ">NJ</Option>
                          <Option value="NM">NM</Option>
                          <Option value="NV">NV</Option>
                          <Option value="NY">NY</Option>
                          <Option value="ND">ND</Option>
                          <Option value="OH">OH</Option>
                          <Option value="OK">OK</Option>
                          <Option value="OR">OR</Option>
                          <Option value="PA">PA</Option>
                          <Option value="RI">RI</Option>
                          <Option value="SC">SC</Option>
                          <Option value="SD">SD</Option>
                          <Option value="TN">TN</Option>
                          <Option value="TX">TX</Option>
                          <Option value="UT">UT</Option>
                          <Option value="VT">VT</Option>
                          <Option value="VA">VA</Option>
                          <Option value="WA">WA</Option>
                          <Option value="WI">WI</Option>
                          <Option value="WV">WV</Option>
                          <Option value="WY">WY</Option>
                        </Select>
                      </StyledInput>
                    </Col>
                    <Col xs>
                      {touched.zip && errors.zip && (
                      <FormIssue>{errors.zip}</FormIssue>
                      )}
                      <Field
                        name="zip"
                        id="zip"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input {...field} size="large" placeholder="Zip" />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs>
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        style={findButton}
                      >
                        Find Account
                      </Button>
                    </Col>
                  </Row>
                </Grid>
                <div>
                  {this.state.addressResults ? this.renderAddressResults() : null}
                  {/* { context.state.accountFound ? <h5><Badge color="success"> Account Found </Badge></h5> : null } */}
                  <Divider />
                  {context.state.accountFound ? <LegalAgreement /> : null}
                  {context.state.accountFound ? (
                    <p>
                      <Button
                        type="primary"
                        size="large"
                        onClick={this.handleStepForward}
                      >
                        {' '}
                        Continue To File A Claim
                        {' '}
                      </Button>
                    </p>
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ValidateAccountByAddressForm.contextType = ClaimContext;

export default ValidateAccountByAddressForm;
