import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
  Modal, Alert, Button, Divider, Tooltip, Icon, message,
} from 'antd';
import {
  faAngleRight,
  faAngleLeft,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';

import ClaimContext from '../claim_context';
import FoodItemsUploadForm from './form_documentation_fooditem_upload';
import FoodItemsListForm from './form_documentation_fooditem';
import ProductUploadForm from './form_documentation_product_upload';

// styled-component for the form (change color on validate)
const StyledInput = styled.div`
  margin: 0 2px 8px 15px;
  width: 100%;
`;
const SectionTitle = styled.h5`
  margin: 10px 4px 4px 0;
  color: #424242;
`;

class Documentation extends Component {
  state = {
    fieldConfig: [],
  };

  constructor(props) {
    super(props);
    this.handleStepForward = this.handleStepForward.bind(this);
    this.handleStepBack = this.handleStepBack.bind(this);
    this.renderFileUploadForms = this.renderFileUploadForms.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
    this.handleAddMoreDocs = this.handleAddMoreDocs.bind(this);
    this.addMoreItems = this.addMoreItems.bind(this);
    this.updateFoodItem = this.updateFoodItem.bind(this);
    this.deleteFoodItem = this.deleteFoodItem.bind(this);
    this.updateDocumentList = this.updateDocumentList.bind(this);
  }

  handleStepForward() {
    const { foodItemList } = this.context.state;

    const foodItemErrList = [];

    for (let i = 0; i < foodItemList.length; i++) {
      if (
        !foodItemList[i].itemLost
        || !foodItemList[i].quantity
        || !foodItemList[i].unitPrice
      ) {
        let err = {};
        err.index = foodItemList[i].index;
        if (!foodItemList[i].quantity) {
          err.quantity = 'Enter valid quantity';
        }
        if (!foodItemList[i].itemLost) {
          err.itemLost = 'Enter valid item lost';
        }
        if (!foodItemList[i].unitPrice) {
          err.unitPrice = 'Enter valid unit price';
        }
        foodItemErrList.push(err);
      }
    }

    if (foodItemErrList.length > 0) {
      this.context.setFoodItemErrList(foodItemErrList);
      return;
    }
    this.context.setFoodItemErrList([]);

    if (
      this.context.state.incident.whatDamagedCode
      === this.context.state.foodItemDamageCode
    ) {
      const docList = this.context.state.documentList;
      if (docList.length > 0) {
        if (!docList[0].id) {
          Modal.error({
            title: 'Validation Error',
            content: 'Document upload is required.',
          });
          return;
        }
        if (!docList[0].documentTitle) {
          Modal.error({
            title: 'Validation Error',
            content: 'Document title is required.',
          });
          return;
        }
        if (!docList[0].photoDescription) {
          Modal.error({
            title: 'Validation Error',
            content: 'Document description is required.',
          });
          return;
        }
      }
    } else {
      const { documentList } = this.context.state;
      const documentErrList = [];

      for (let i = 0; i < documentList.length; i++) {
        if (
          !documentList[i].documentTitle
          || !documentList[i].id
          || (!documentList[i].estimatedValue && this.context.state.documentFieldConfig.indexOf('PURCHASE_VALUE') !== -1)
          || !documentList[i].photoDescription
          || (!documentList[i].yearPurchased && this.context.state.documentFieldConfig.indexOf('YEAR') !== -1)
        ) {
          var err = {};
          err.index = documentList[i].index;
          if (!documentList[i].documentTitle) {
            err.documentTitle = 'Required';
          }
          if (!documentList[i].id) {
            err.id = 'Attachment required.';
          }
          if (!documentList[i].photoDescription) {
            err.photoDescription = 'Required';
          }

          if (!documentList[i].estimatedValue) {
            if (this.context.state.documentFieldConfig.indexOf('PURCHASE_VALUE') !== -1) {
              err.estimatedValue = 'Required';
            }
          }

          if (!documentList[i].yearPurchased) {
            if (this.context.state.documentFieldConfig.indexOf('YEAR') !== -1) {
              err.yearPurchased = 'Required';
            }
          }

          documentErrList.push(err);
        }
      }

      if (documentErrList.length > 0) {
        this.context.setDocumentErrList(documentErrList);
        Modal.error({
          title: 'Validation Error',
          content: 'Please fill in the required fields.',
        });
        return;
      }
      this.context.setDocumentErrList([]);
    }

    this.context.setStep(4);
  }

  handleStepBack() {
    this.context.setStep(2);
  }

  addMoreItems() {
    const { foodItemList } = this.context.state;
    let { foodItemIndex } = this.context.state;
    foodItemIndex++;
    const item = {
      itemLost: '',
      quantity: 0,
      unitPrice: 0,
      index: foodItemIndex,
    };
    foodItemList.push(item);
    this.context.setFoodItemList(foodItemList);
    this.context.setFoodItemIndex(foodItemIndex);
  }

  addMoreDocuments = () => {
    const docList = this.context.state.documentList;
    let { documentListIndex } = this.context.state;
    documentListIndex++;
    const doc = {
      index: documentListIndex,
      fileList: [],
    };
    docList.push(doc);
    this.context.setDocumentList(docList);
    this.context.setDocumentListIndex(documentListIndex);
  };

  updateFoodItem = (item, field) => {
    const { foodItemList } = this.context.state;

    const modifiedFoodItem = [];

    for (let i = 0; i < foodItemList.length; i++) {
      if (foodItemList[i].index === item.index) {
        foodItemList[i][field] = item[field];
      }
      modifiedFoodItem.push(foodItemList[i]);
    }

    const total = modifiedFoodItem
      .map((item) => item.quantity * item.unitPrice)
      .reduce((sum, el) => sum + el, 0);

    this.context.setFoodItemList(modifiedFoodItem);
    this.context.setFoodItemTotal(total);
  };

  updateDocument = (document) => {
    const { documentList } = this.context.state;

    const modifiedDocList = [];

    for (let i = 0; i < documentList.length; i++) {
      if (documentList[i].index === document.index) {
        modifiedDocList.push(document);
      } else {
        modifiedDocList.push(documentList[i]);
      }
    }
    this.context.setDocumentList(modifiedDocList);
    this.revalidateDocumentErrors(modifiedDocList);
  };

  revalidateDocumentErrors = (documentList) => {
    const { documentErrList } = this.context.state;

    const modifiedErrList = [];

    for (var i = 0; i < documentList.length; i++) {
      if (documentErrList) {
        const docErr = documentErrList.find((err) => err.index === documentList[i].index);
        if (docErr) {
          if (!documentList[i].documentTitle) {
            docErr.documentTitle = 'Required';
          } else {
            docErr.documentTitle = '';
          }
          if (!documentList[i].id) {
            docErr.id = 'Attachment required.';
          } else {
            docErr.id = '';
          }
          if (!documentList[i].photoDescription) {
            docErr.photoDescription = 'Required';
          } else {
            docErr.photoDescription = '';
          }

          if (!documentList[i].estimatedValue) {
            docErr.estimatedValue = 'Required';
          } else {
            docErr.estimatedValue = '';
          }

          if (!documentList[i].yearPurchased) {
            docErr.yearPurchased = 'Required';
          } else {
            docErr.yearPurchased = '';
          }

          modifiedErrList.push(docErr);
        }
      }
    }

    this.context.setDocumentErrList(modifiedErrList);
  };

  updateDocumentList = (doc, field) => {
    this.context.setDocumentList([doc]);
    this.context.setDisableFileSaveContinue(false);
  };

  deleteFoodItem = (index) => {
    const { foodItemList } = this.context.state;
    const modifiedFoodItem = [];

    for (let i = 0; i < foodItemList.length; i++) {
      if (foodItemList[i].index !== index) {
        modifiedFoodItem.push(foodItemList[i]);
      }
    }
    const total = modifiedFoodItem
      .map((item) => item.quantity * item.unitPrice)
      .reduce((sum, el) => sum + el, 0);
    this.context.setFoodItemTotal(total);
    this.context.setFoodItemList(modifiedFoodItem);
  };

  deleteDocument = (index) => {
    const docList = this.context.state.documentList;
    const modifiedDocList = [];

    for (let i = 0; i < docList.length; i++) {
      if (docList[i].index !== index) {
        modifiedDocList.push(docList[i]);
      }
    }
    this.context.setDocumentList(modifiedDocList);
  };

  handleAddMoreDocs() {
    this.context.setShowAddFilesForm(true);
  }

  renderFileUploadForms() {
    // If it's a food item list show that for the upload form
    if (
      this.context.state.incident.whatDamagedCode
      === this.context.state.foodItemDamageCode
    ) {
      let docData = [];
      if (
        this.context.state.documentList
        && this.context.state.documentList.length > 0
      ) {
        docData = this.context.state.documentList;
      } else {
        docData = [
          {
            documentTitle: '',
            id: 0,
            photoDescription: '',
          },
        ];
      }
      return (
        <>
          {this.context.state.foodItemUploadForms.map((i) => (
            <FoodItemsUploadForm
              key={i}
              change={this.updateDocumentList}
              data={docData[0]}
              guid={this.context.state.guid}
            />
          ))}
        </>
      );
    }

    // Load up damange description items based on data model
    const filteredArr = this.context.state.incidentDataModel.filter(
      (item) => item.code === this.context.state.incident.whatDamagedCode,
    );
    let { fieldConfig } = filteredArr[0];

    if (fieldConfig != null) {
      fieldConfig = fieldConfig.split(',');
    }

    const { documentErrList } = this.context.state;
    const docDisplayList = [];
    const docList = this.context.state.documentList;
    let errors;
    for (var i = 0; i < docList.length; i++) {
      if (documentErrList) {
        errors = documentErrList.find((err) => err.index === docList[i].index);
      }

      docDisplayList.push(
        <ProductUploadForm
          key={docList[i].index}
          delete={this.deleteDocument}
          disableDelete={this.context.state.documentList.length < 2}
          guid={this.context.state.guid}
          change={this.updateDocument}
          document={docList[i]}
          errors={errors}
          fieldConfig={fieldConfig}
        />,
      );
    }

    return <>{docDisplayList}</>;
  }

  renderSubmitButton() {
    return (
      <div>
        <Button
          type="primary"
          style={{ float: 'right' }}
          onClick={this.handleStepForward}
        >
          Save & Continue
          {' '}
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{ marginLeft: '4px', fontSize: '16px' }}
          />
        </Button>
      </div>
    );
  }

  renderFoodItemFormsList() {
    if (
      this.context.state.incident.whatDamagedCode
      === this.context.state.foodItemDamageCode
    ) {
      const { foodItemList } = this.context.state;
      const { foodItemErrList } = this.context.state;
      console.log(foodItemErrList);

      if (foodItemList.length < 1) {
        const item = {
          itemLost: '',
          quantity: 0,
          unitPrice: 0,
          index: 1,
        };
        foodItemList.push(item);
        this.context.setFoodItemList(foodItemList);
        this.context.setFoodItemIndex(1);
        this.context.setFoodItemTotal(0);
      }

      const foodDisplayList = [];
      this.context.state.foodItemList.map((item) => {
        let errors;
        if (foodItemErrList) {
          errors = foodItemErrList.find((err) => err.index === item.index);
        }
        foodDisplayList.push(
          <Row>
            <Col xs>
              <FoodItemsListForm
                key={item.index}
                item={item}
                change={this.updateFoodItem}
                delete={() => this.deleteFoodItem(item.index)}
                errors={errors}
              />
            </Col>
          </Row>,
        );
      });

      return (
        <>
          <Row>
            <Col xs>
              <Row>
                <Col xs>
                  <Divider>List Out Food Items</Divider>
                </Col>
              </Row>
              <Row>
                <Col xs />
                <Col xs />
                <Col xs />
                <Col xs>
                  <strong>Est value: </strong>
                  $
                  {this.context.state.foodItemTotal ? this.context.state.foodItemTotal.toFixed(2) : '0.00'}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs>
              <Row>
                <Col xs>
                  <StyledInput>
                    <SectionTitle>Item</SectionTitle>
                  </StyledInput>
                </Col>
                <Col xs>
                  <StyledInput>
                    <SectionTitle>Quantity</SectionTitle>
                  </StyledInput>
                </Col>
                <Col xs>
                  <StyledInput>
                    <SectionTitle>Unit Price</SectionTitle>
                  </StyledInput>
                </Col>
                <Col xs> </Col>
              </Row>
            </Col>
          </Row>
          {foodDisplayList}
          <Row>
            <Col xs>
              <Button type="primary" onClick={this.addMoreItems}>
                Add More Items
              </Button>
            </Col>
            <Col xs />
          </Row>
        </>
      );
    }

    return null;
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <Grid>
            <Row>
              <Col xs={12}>
                <Divider>
                  <h5>Upload Photos of Damage/Incident</h5>
                </Divider>
                <Alert
                  message="Upload Required"
                  description="The claim must include supporting documentation. (Pictures, receipts, etc.)"
                  type="info"
                  showIcon
                  style={{ marginBottom: '20px' }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs>
                <Row>
                  <Col xs>{this.renderFileUploadForms()}</Col>
                </Row>
                <Row>
                  <Col xs>
                    <Button
                      type="primary"
                      hidden={
                        this.context.state.incident.whatDamagedCode
                          === this.context.state.foodItemDamageCode
                        || this.context.state.documentList.length === 8
                      }
                      onClick={this.addMoreDocuments}
                      style={{ marginTop: '10px' }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{
                          marginRight: '4px',
                          marginTop: '5px',
                          fontSize: '16px',
                        }}
                      />
                      {' '}
                      Add More Documents
                    </Button>
                  </Col>
                </Row>

                {this.renderFoodItemFormsList()}
                <Row>
                  <Col xs>
                    <Divider />
                  </Col>
                </Row>

                <div style={{ marginTop: '25px' }}>
                  <Row>
                    <Col xs>
                      <Button type="primary" onClick={this.handleStepBack}>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          style={{ marginRight: '4px', fontSize: '16px' }}
                        />
                        {' '}
                        Back
                      </Button>
                    </Col>
                    <Col xs>{this.renderSubmitButton()}</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Grid>
        )}
      </ClaimContext.Consumer>
    );
  }
}

Documentation.contextType = ClaimContext;

export default Documentation;
