import _ from 'lodash';
import React, { Component } from 'react';
import { FilePdfOutlined , FileOutlined} from '@ant-design/icons';

import ClaimContext from './claim_context';

class DocumentListItem extends Component {
  constructor(props) {
    super(props);
    this.renderUploadThumb = this.renderUploadThumb.bind(this);
  }

  renderUploadThumb() {
    if (_.includes(this.props.document.fileType, 'image')) {
      return (
        <div style={{ width: '150px' }}>
          <img
            src={this.props.document?.fileListItem?.thumbUrl ? this.props.document.fileListItem.thumbUrl : ' '}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      );
    }
    if (_.includes(this.props.document.fileType, 'pdf')) {
      return (
        <div style={{ width: '100px' }}>
          <FilePdfOutlined style={{ fontSize: '25px', color: '#BFBFBF' }} />
        </div>
      );
    }
    return (
      <div style={{ width: '100px' }}>
        <FileOutlined style={{ fontSize: '25px', color: '#BFBFBF' }} />
      </div>
    );
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {() => (
          <>
            { this.renderUploadThumb() }
            <p>{this.props.document.documentTitle}</p>
            <p>{this.props.document.photoDescription}</p>
            <p>{this.props.document.productMake ? `Make: ${this.props.document.productMake}` : null }</p>
            <p>{this.props.document.productModel ? `Model: ${this.props.document.productModel}` : null }</p>
            <p>{this.props.document.yearPurchased ? `Year: ${this.props.document.yearPurchased}` : null }</p>
            <p>{this.props.document.estimatedValue ? `Value: $${this.props.document.estimatedValue}` : null }</p>
          </>
        )}
      </ClaimContext.Consumer>
    );
  }
}

DocumentListItem.contextType = ClaimContext;

export default DocumentListItem;
