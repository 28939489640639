import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'gatsby';
import { Collapse, Card, CardBody } from 'reactstrap';
import { Button, Divider } from 'antd';
import {
  faMapMarkerAlt,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import claimsCover from '../../images/claims_cover.png';
import claimsCover2x from '../../images/claims_cover@2x.png';
import featureBg from '../../images/feature_bg.png';

import ClaimContext from '../claim_context';
import ValidateAccountForm from '../forms/form_validate_account_number';
import FindAccountByPhone from '../forms/form_validate_account_phone';
import FindAccountByAddress from '../forms/form_validate_account_address';

const ButtonGroup = Button.Group;

// styled-component for the form (change color on validate)
const FormWrapper = styled.div`
  width: 100hv;
  border: ${(props) => (props.success ? '1px solid green' : '')};
  background-color: ${(props) => (props.success ? 'rgba(55, 125, 34, 0.1)' : '')};
  border-radius: 7px;
`;

const SuccessCheckmark = styled.div``;

const AlternateLookup = styled.div`
  font-size: 12px;
  cursor: pointer;
`;

const Or = styled.div`
  font-size: 14px;
  color: #929292;
`;

class AccountLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountValidation: false,
      showPhoneSupport: false,
    };
    this.handleAccountValidation = this.handleAccountValidation.bind(this);
    this.handlePhoneSupport = this.handlePhoneSupport.bind(this);
    this.handleFindByPhone = this.handleFindByPhone.bind(this);
    this.handleFindByAddress = this.handleFindByAddress.bind(this);
    this.handleFindByAccount = this.handleFindByAccount.bind(this);
  }

  handleFindByPhone() {
    this.context.setValidateBy('Account Phone');
  }

  handleFindByAddress() {
    this.context.setValidateBy('Account Address');
  }

  handleFindByAccount() {
    this.context.setValidateBy('Account Number');
  }

  handleAccountValidation() {
    this.setState({
      showAccountValidation: true,
      showPhoneSupport: false,
    });
  }

  handlePhoneSupport() {
    this.setState({
      showPhoneSupport: true,
      showAccountValidation: false,
    });
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Grid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h3 style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
                  Begin Claim Submission
                  {' '}
                </h3>
                <Divider />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <div style={{ marginBottom: '20px' }}>
                  <p>
                    Do you currently have an account with American Electric
                    Power?
                  </p>
                  <ButtonGroup>
                    <Button
                      id="yes_button"
                      name="yes_button"
                      size="large"
                      type="primary"
                      onClick={this.handleAccountValidation}
                    >
                      Yes
                    </Button>
                    <Button
                      id="no_button"
                      name="no_button"
                      size="large"
                      type="primary"
                      onClick={this.handlePhoneSupport}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </div>
                <FormWrapper success={context.state.accountFound} bgImg>
                  <Collapse
                    style={{ width: '100hv' }}
                    isOpen={this.state.showAccountValidation}
                  >
                    <Card
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                    >
                      <SuccessCheckmark>
                        <CardBody style={{ padding: '4px', paddingTop: '8px' }}>
                          {context.state.validateBy === 'Account Number' && (
                            <ValidateAccountForm />
                          )}
                          {context.state.validateBy === 'Account Phone' && (
                            <FindAccountByPhone />
                          )}
                          {context.state.validateBy === 'Account Address' && (
                            <FindAccountByAddress />
                          )}

                          {context.state.accountFound ? null : <Or>- or -</Or>}
                          {context.state.accountFound
                          || context.state.validateBy
                            === 'Account Phone' ? null : (
                              <AlternateLookup id="findByPhone" onClick={this.handleFindByPhone}>
                                <FontAwesomeIcon
                                  icon={faPhone}
                                  style={{ marginRight: '3px' }}
                                />
                                Find Account by Phone Number
                              </AlternateLookup>
                            )}
                          {context.state.accountFound
                          || context.state.validateBy
                            === 'Account Address' ? null : (
                              <AlternateLookup id="findByAddress" onClick={this.handleFindByAddress}>
                                <FontAwesomeIcon
                                  icon={faMapMarkerAlt}
                                  style={{ marginRight: '3px' }}
                                />
                                {' '}
                                Find Account by My Address
                              </AlternateLookup>
                            )}
                          {context.state.accountFound
                          || context.state.validateBy
                            === 'Account Number' ? null : (
                              <AlternateLookup onClick={this.handleFindByAccount}>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  style={{ marginRight: '3px' }}
                                />
                                {' '}
                                Find Account by AEP Account Number
                              </AlternateLookup>
                            )}
                        </CardBody>
                      </SuccessCheckmark>
                    </Card>
                  </Collapse>
                  <Collapse
                    style={{ width: '100hv' }}
                    isOpen={this.state.showPhoneSupport}
                  >
                    <Card
                      style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        marginBottom: '15px',
                      }}
                    >
                      <CardBody>
                        <p>
                          <span>
                            This on-line claim submission site is restricted to current AEP residential customers only.
                            If you are not a residential customer, please call (800) 277-2177 to speak with a customer
                            service representative about filing a claim.
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Collapse>
                </FormWrapper>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <div
                  style={{
                    backgroundImage: `url(${featureBg})`,
                    backgroundRepeat: 'no-repeat',
                    textAlign: 'center',
                    width: '100hv',
                    minHeight: '400px',
                  }}
                >
                  <div style={{ textAlign: 'left', width: '100hv' }}>
                    <p> AEP Online Claims Submission Resources</p>
                  </div>

                  <div
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      width: '100hv',
                    }}
                  >
                    <p>
                      <Link to="/help/claims_overview/" target="_blank">
                        Read about our coverage and claims process
                      </Link>
                    </p>
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      width: '100hv',
                    }}
                  >
                    <p>
                      <Link to="/help/claims_tips/" target="_blank">
                        Tips for submitting claims
                      </Link>
                    </p>
                  </div>
                  <img
                    src={claimsCover}
                    alt="claimsCover"
                    srcSet={`${claimsCover2x} 2x`}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        )}
      </ClaimContext.Consumer>
    );
  }
}

AccountLookup.contextType = ClaimContext;

export default AccountLookup;
