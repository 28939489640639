import _ from 'lodash';
import * as yup from 'yup';
import axios from 'axios';
import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import MaskedInput from 'react-text-mask';
import {
  Alert, Popover, Badge, Button, Input, Divider,
} from 'antd';
import {
  faCheckCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';

import LegalAgreement from '../legal_agreement';
import CompanyTermsDataModel from '../../content/CompanyTermsDataModel.json';

import ClaimContext from '../claim_context';
import billSample from '../../images/bill_sample_800.png';

const StyledInput = styled.div`
  margin: 0 2px 8px 0;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;
const findButton = {
  margin: '8px 0 0 0',
};

const billSampleHelp = (
  <div>
    <img src={billSample} alt="AEP Bill Sample" />
  </div>
);

class ValidateAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showNotFound: false };
    this.handleStepForward = this.handleStepForward.bind(this);
    this.skiptodocs = this.skiptodocs.bind(this);
  }

  handleStepForward() {
    this.context.setStep(1);
  }

  skiptodocs() {
    this.context.setStep(3);
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              aepAccountNumber: '',
            }}
            validationSchema={yup.object().shape({
              firstName: yup.string().required('First Name required'),
              lastName: yup.string().required('Last Name required'),
              aepAccountNumber: yup
                .string()
                .required('Please enter an account number'),
            })}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              // actions.validateForm();
              let accountNum = values.aepAccountNumber;

              if (accountNum.length === 13) {
                accountNum = accountNum.replace(/-/g, ''); // Clean up mask artifacts

                try {
                  const endpoint = `${process.env.API_URL}/searchaccount`;
                  const response = await axios.post(endpoint, {
                    accountNumber: accountNum,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    searchBy: 'account',
                  });
                  this.setState({
                    showNotFound: false,
                  });
                  const account = response.data.accounts[0];
                  this.context.setGuidId(account.guid);
                  actions.setSubmitting(false);
                  this.context.setAccountFound(true);

                  const { guid } = account;
                  this.context.setCompanyCode(guid.substring(0, 2));
                  this.context.setCustomerInformation(values);
                  const companyCode = guid.substring(0, 2); // Grab first 2 for comapny code

                  // Get the agreement based on users company code and state
                  const legalDoc = _.find(CompanyTermsDataModel, {
                    companyCode: companyCode,
                    state: account.address.stateCode,
                  });
                  this.context.setCustomerLegal(legalDoc);
                } catch (error) {
                  // console.error(error);
                  this.setState({
                    showNotFound: true,
                  });
                  actions.setSubmitting(false);
                  this.context.setAccountFound(false);
                }
              } else {
                this.setState({
                  showNotFound: true,
                });
                actions.setSubmitting(false);
              }
            }}

          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form id="form_validate_account_number">
                <Grid>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <p>Enter the name associated with your account: </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      {touched.firstName && errors.firstName && (
                      <FormIssue>{errors.firstName}</FormIssue>
                      )}
                      <Field
                        name="firstName"
                        id="firstName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              name="firstName"
                              id="firstName"
                              size="large"
                              placeholder="First Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      {touched.lastName && errors.lastName && (
                      <FormIssue>{errors.lastName}</FormIssue>
                      )}
                      <Field
                        name="lastName"
                        id="lastName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              name="lastName"
                              id="lastName"
                              size="large"
                              placeholder="Last Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <p>Enter Your Account Number: </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      {touched.aepAccountNumber && errors.aepAccountNumber && (
                      <FormIssue>{errors.aepAccountNumber}</FormIssue>
                      )}
                      {/* <Field  placeholder="Account Number" /> */}
                      <Field
                        type="aepAccountNumber"
                        name="aepAccountNumber"
                      >
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            className="custom_input"
                            id="aepAccountNumber_masked"
                            placeholder="Account Number"
                            guide={false}
                            mask={[
                              /[0-9]/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                            ]}
                            style={{
                              padding: '6px 11px',
                              height: '40px',
                              fontSize: '16px',
                              borderRadius: '4px',
                              border: '1px solid #d9d9d9',
                              transition: 'all .3s',
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Button
                        id="findAccount"
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                      // onClick={this.skiptodocs}
                        loading={isSubmitting}
                        style={findButton}
                      >
                        {' '}
                        Find Account
                      </Button>
                      {context.state.accountFound ? (
                        <FontAwesomeIcon
                          style={{ marginLeft: '5px' }}
                          icon={faCheckCircle}
                          color="#53a451"
                        />
                      ) : null}
                      {context.state.accountFound ? (
                        <h6>
                          <Badge status="success" text="Account Found" />
                        </h6>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Divider />
                      {this.state.showNotFound ? (
                        <div style={{ marginTop: '5px' }}>
                          <Alert
                            message="Not Found"
                            description="We could not find an account with those values."
                            type="error"
                            showIcon
                          />
                        </div>
                      ) : null}
                      {context.state.accountFound ? <LegalAgreement /> : null}
                      {context.state.accountFound ? (
                        <p>
                          <Button
                            type="primary"
                            size="large"
                            onClick={this.handleStepForward}
                          >
                            {' '}
                            Continue To File A Claim
                            {' '}
                          </Button>
                        </p>
                      ) : null}
                      {context.state.accountFound ? null : (
                        <Popover
                          content={billSampleHelp}
                          placement="right"
                          title="Bill Sample for Account Number Lookup"
                          trigger="hover"
                        >
                          <div
                            style={{
                              fontSize: '12px',
                              width: '250px',
                              cursor: 'pointer',
                            }}
                          >
                            {' '}
                            Need help finding account number?
                            {' '}
                            <FontAwesomeIcon icon={faQuestionCircle} />
                          </div>
                        </Popover>
                      )}
                    </Col>
                  </Row>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ValidateAccountForm.contextType = ClaimContext;

export default ValidateAccountForm;
