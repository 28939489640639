import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Divider } from 'antd';

import claimsCover from '../../images/claims_cover.png';
import claimsCover2x from '../../images/claims_cover@2x.png';
import featureBg from '../../images/feature_bg.png';

import ClaimContext from '../claim_context';
import CustomerInfoForm from '../forms/form_customer_information';

class CustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.onbeforeunload = () => 'Are you sure you want to leave this page?  You will lose any unsaved data.';
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Grid>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h3 style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
                  Customer Information
                </h3>
                <Divider />
              </Col>
            </Row>
            <div style={{ minHeight: '360px' }}>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <CustomerInfoForm context={context} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <Divider>Claim Summary</Divider>
                  <div
                    style={{
                      backgroundImage: `url(${featureBg})`,
                      backgroundRepeat: 'no-repeat',
                      textAlign: 'center',
                      width: '100hv',
                    }}
                  >
                    <img
                      src={claimsCover}
                      srcSet={`${claimsCover2x} 2x`}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Grid>
        )}
      </ClaimContext.Consumer>
    );
  }
}

CustomerInfo.contextType = ClaimContext;

export default CustomerInfo;
